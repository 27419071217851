class ValidatorUtil {
    
    static hasValue(text,minLength = 2) {
       if(!text){
        return false;
       }
       if(text.length < minLength){
        return false;
       }
       return true;
    }
    static isPhoneValid(phone){
      const reIL = /^97205[0-9]{8}$/;
       const reUS = /^1[0-9]{10}$/;
       const reUK = /^44[0-9]{10,11}$/;
       
       return reIL.test(phone) ||
       reUS.test(phone) ||
       reUK.test(phone); 

    }

    static isEmailValid(email) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }
   
    static isPasswordValid(password){
        if(this.hasLowerCase(password) 
            && this.hasUpperCase(password)
            && this.hasSpecialCharacters(password)){
                return true
            }

            return false;

    }
  
    static hasLowerCase(str) {
        return (/[a-z]/.test(str));
    }
   
    static hasUpperCase(str) {
        return (/[A-Z]/.test(str));
    }
   
    static hasSpecialCharacters(str){
        const format = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
        return format.test(str);
    }
    static isEmptyField(str){
        return str === undefined || str === null ||
        str === '';
    }
}

export default ValidatorUtil;