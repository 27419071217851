import React,{useEffect} from 'react';

import Nav from './nav'
import Main from './content/main';
import Sidebar from './content/sidebar';
import { RouteContext } from './contexts/route.context';
import { DynamicForm } from './pages/dynamic-form/dynamicForm';
import { Route, Routes,BrowserRouter as Router,createBrowserRouter, useLocation } from 'react-router-dom';

const Layout = () =>{
    const [title,setTitle] = React.useState('');
    const location = useLocation();

    useEffect(()=>{
        console.log(location)
    },[location])

    return(<React.Fragment>
        { location.pathname !== '/form' && <div className="top-bar" style={{height:"5rem",background:"#1d55a6"}}>
        <ul >
            <li>{title}</li>
            {/* <li>Groups
                <ul className="nested">
                    <li>Group 1</li>
                    <li>Group 2</li>
                    <li>Group 3</li>
                </ul>
            </li>
            <li>Lessons</li> */}
        </ul>
    </div>}
        {/* <Nav /> */}
        <RouteContext.Provider value={{onRouteChanged:setTitle}} >
        <div className="layout">
            <Sidebar onRouteChanged={setTitle} />
            <Main />
        </div>
        
        </RouteContext.Provider>
    </React.Fragment>)
}

export default Layout;