import React from 'react';

const Checkbox = ({title,field,onChange}) => {
    const onCheckboxClicked = ({target}) =>{
        onChange(field,target.checked)
    }
    return (<div><span style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
        {title}
        <label className="checkbox">
            <input type="checkbox" onClick={onCheckboxClicked} />
            <span className="primary"></span>
        </label></span></div>)
}

export default Checkbox;