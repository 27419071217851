import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { axiosInstance} from "../../axiosInstance";
import {toast} from 'react-toastify'

const URL = "/api/v1/lessonssubcategories"
export const getLessonsSubCategories = createAsyncThunk(
    'lessonsSubCategories/get',
    async () => {
      try {
       
        const response = await axiosInstance.getInstance().get(URL)
     
        return response.data
      } catch (err) {
        console.log(err)
      }
    }
  )
  export const getLessonsSubCategoriesByName = createAsyncThunk(
    'getLessonsSubCategoriesByName/get',
    async (name) => {
      try {
       
        const response = await axiosInstance.getInstance().get(URL+`/getByName?name=${name}`)
     
        return response.data
      } catch (err) {
        console.log(err)
      }
    }
  )
  export const addLessonsSubCategories = createAsyncThunk(
    'lessonsSubCategories/add',
    async (lessonsreferences) => {
      try {
       
        const response = await axiosInstance.getInstance().post(URL,lessonsreferences)
        toast("הוספת רשומה לקטגוריות בוצעה בהצלחה",{ position: toast.POSITION.TOP_CENTER })
        return response.data
      } catch (err) {
        toast.error("הוספת רשומה לקטגוריות נכשל",{ position: toast.POSITION.TOP_CENTER })
        console.log(err)
      }
    }
  )

  export const lessonsSubCategoriesSlice = createSlice({
    name: "lessonsSubCategories",
    initialState: {
        data: [],
      loading:false
    },
    reducers: {
    fetchLessonSubCategories(state, action) {
        state.data = action.payload
      }
    },
    extraReducers: (builder) => {
        builder.addCase(getLessonsSubCategories.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.data = payload;
         
        })
        builder.addCase(getLessonsSubCategories.pending, (state, { payload }) => {
            state.loading = true;
        })
        builder.addCase(getLessonsSubCategories.rejected, (state, { payload }) => {
            state.loading = false;
        })
        builder.addCase(getLessonsSubCategoriesByName.fulfilled, (state, { payload }) => {
          state.loading = false;
          state.data = payload;
       
      })
      builder.addCase(getLessonsSubCategoriesByName.pending, (state, { payload }) => {
          state.loading = true;
      })
      builder.addCase(getLessonsSubCategoriesByName.rejected, (state, { payload }) => {
          state.loading = false;
      })
        builder.addCase(addLessonsSubCategories.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.data = [...state.data,payload];
        
        })
        builder.addCase(addLessonsSubCategories.pending, (state, { payload }) => {
            state.loading = true;
        })
        builder.addCase(addLessonsSubCategories.rejected, (state, { payload }) => {
            state.loading = false;
        })
        // add more case
      }
  })
  export const { fetchLessonSubCategories } = lessonsSubCategoriesSlice.actions