import React from 'react';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';


const SelectInputForm = ({title,className,options,onChange,value,errorMessage}) =>{
    return( <div  className={'form-group' + (className ? ' ' + className : '')}>
    <label >{title}</label>
    <Dropdown 
    className={'Dropdown-root'}
    options={options} 
    onChange={onChange} 
    value={value} 
    placeholder="Select an option"
     />
     {errorMessage && <p style={{color:'red'}}>{errorMessage}</p>}
  </div>)
}

export default SelectInputForm;