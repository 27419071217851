import React, { useState,useEffect } from 'react';
import {useDispatch,useSelector} from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'

import Modal from '../components/modals/modal';
import { getContacts } from '../redux/reducers/contacts-reducer';
import UserForm from "../components/forms/user-form";
import THead from '../components/tables/thead';
import ContactRow from '../components/tables/ContactRow';


const ContactsPage = () => {

  const dispatch = useDispatch();
  const contacts = useSelector(state=>state.contacts);
  const [shouldOpenModal, setShouldOpenModal] = useState(false);
  const [productItem,setProductItem] = useState(null);
 
  useEffect(()=>{
    dispatch(getContacts())
  },[]);

  const onAdd = () =>{
    setProductItem(null);
    setShouldOpenModal(true);
  }
  const onEdit = (index) =>{
    setProductItem(contacts[index]);
    setShouldOpenModal(true);
  }

  const onRemove = (index) =>{
    const delivery = contacts[index];
   
  }

  const onSubmit = (item) =>{
    
      if(item.id){
          
      }else{
      
      }
  }
  
  const getContactRows = () =>{
     if(contacts && contacts.data){
       return contacts.data.map((item,index)=>{
         return <ContactRow 
                 key={index} 
                 item={item} index={index}
                 onEdit={onEdit}
                 onRemove={onRemove}
                  />;})
     }
  }

  return (<div >
    <Modal shouldOpen={shouldOpenModal} title={'טופס משלוחים'} onClose={() => { setShouldOpenModal(false) }}>
      <UserForm item={productItem} onSubmit={onSubmit} />
     </Modal>
    <table className="table table-stripped" style={{ width: '85%', margin: '10vh auto', fontSize: '1.3rem', background: '#fff', boxShadow: '1px 1px #888888' }}>
    <THead 
      headers={ ["מספר מזהה","שם הרב","תוכן","סוג","שם","",""]}
    />
     
      <tbody>
        {getContactRows()}
      </tbody>
    </table>
    <button className="button-circle" onClick={onAdd}>
       <FontAwesomeIcon icon={faPlus} />
    </button>
  </div>)
}

export default ContactsPage;