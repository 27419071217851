import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { axiosInstance} from "../../axiosInstance";
import {  toast,Slide } from 'react-toastify';
import ArrayUtil from "../../utils/array-util";
import _ from 'underscore';


export const getLessonsReferences = createAsyncThunk(
    'lessonsReferences/get',
    async ({pageNum,countPerPage}) => {
      try {
        
        const response = await axiosInstance.getInstance().get("/api/v1/lessonsreferences", {
          headers: {
              pageNum,
             countPerPage
          }
      })
      return {
        data:response.data.result,
        count:response.headers['x-count'],
        pageNum:response.data.pageNum
      }
       
      } catch (err) {
        console.log(err)
      }
    }
  )
  export const getLessonsReferencesByCategory = createAsyncThunk(
    'lessonsReferences/getByCategory',
    async (categoryId) => {
      try {
       
        const response = await axiosInstance.getInstance().get("/api/v1/lessonsreferences/getByCategory/" +  categoryId)
      return {
        data:response.data,
        count:response.data.length
      }
      } catch (err) {
        console.log(err)
      }
    }
  )
  export const addLessonsReferences = createAsyncThunk(
    'lessonsReferences/add',
    async (lessonsreferences) => {
      try {
       
        const response = await axiosInstance.getInstance().post("/api/v1/lessonsreferences",lessonsreferences, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
        })
        toast("הוספת שיעור בוצעה בהצלחה",{ position: toast.POSITION.BOTTOM_RIGHT,transition:Slide })
        return response.data
      } catch (err) {
        console.log(err)
      }
    }
  )
  export const addLessonsReferencesContent = createAsyncThunk(
    'lessonsReferences/addContent',
    async (lessonsreferences) => {
      try {
       
        const response = await axiosInstance.getInstance().post("/api/v1/lessonsreferences/addContent",lessonsreferences,)
        toast("הוספת שיעור בוצעה בהצלחה",{ position: toast.POSITION.BOTTOM_RIGHT,transition:Slide })
        return response.data
      } catch (err) {
        console.log(err)
      }
    }
  )
  export const updateLessonsReferences = createAsyncThunk(
    'lessonsReferences/update',
    async (formData) => {
      try {
       
        const response = await axiosInstance.getInstance().put("/api/v1/lessonsreferences/" + formData.id,formData)
        toast("עידכון שיעור בוצע בהצלחה",{ position: toast.POSITION.BOTTOM_RIGHT,transition:Slide })
        return response.data
      } catch (err) {
        console.log(err)
      }
    }
  )
  export const deleteLessonsReferences = createAsyncThunk(
    'lessonsReferences/delete',
    async (id,{ getState }) => {
      try {
       const state = getState().lessonsReferences;
        const response = await axiosInstance.getInstance().delete("/api/v1/lessonsreferences/" + id)
        toast("מחיקת שיעור בוצע בהצלחה",{ position: toast.POSITION.BOTTOM_RIGHT,transition:Slide })
        return {id}
      } catch (err) {
        console.log(err)
      }
    }
  )

  export const lessonsReferencesSlice = createSlice({
    name: "lessonReferences",
    initialState: {
        data: [],
        pageNum:1,
        totalPages:1,
        length:0,
      loading:false
    },
    reducers: {
    fetchLessonReferences(state, action) {
        state.data = action.payload
      }
    },
    extraReducers: (builder) => {
        builder.addCase(getLessonsReferences.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.data = payload && payload.data ? payload.data : [];
            state.pageNum = payload && payload.pageNum ? payload.pageNum : 1;
            state.length = payload && payload.count ?  +payload.count : 0
            state.totalPages = state.length / 50 + (state.length % 50 === 0 ? 0 : 1);
         
        })
        builder.addCase(getLessonsReferences.pending, (state, { payload }) => {
            state.loading = true;
        })
        builder.addCase(getLessonsReferences.rejected, (state, { payload }) => {
            state.loading = false;
        })
        builder.addCase(getLessonsReferencesByCategory.fulfilled, (state, { payload }) => {
          state.loading = false;
          state.data = payload.data || [];
          state.length = +payload.count
       
      })
      builder.addCase(getLessonsReferencesByCategory.pending, (state, { payload }) => {
          state.loading = true;
      })
      builder.addCase(getLessonsReferencesByCategory.rejected, (state, { payload }) => {
          state.loading = false;
      })
        builder.addCase(addLessonsReferences.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.data = [...state.data,payload];
        
        })
        builder.addCase(addLessonsReferences.pending, (state, { payload }) => {
            state.loading = true;
        })
        builder.addCase(addLessonsReferences.rejected, (state, { payload }) => {
            state.loading = false;
        })
        builder.addCase(updateLessonsReferences.fulfilled, (state, { payload }) => {
          state.loading = false;
          const index = ArrayUtil.getIndexById(state.data,payload.id)
          state.data[index] = payload
          state.data = [...state.data];
      
      })
      builder.addCase(updateLessonsReferences.pending, (state, { payload }) => {
          state.loading = true;
      })
      builder.addCase(updateLessonsReferences.rejected, (state, { payload }) => {
          state.loading = false;
      })
      builder.addCase(deleteLessonsReferences.rejected, (state, { payload }) => {
        state.loading = false;
    })
    builder.addCase(deleteLessonsReferences.pending, (state, { payload }) => {
      state.loading = true;
  })
  builder.addCase(deleteLessonsReferences.fulfilled, (state, { payload }) => {
    state.loading = false;
    const ind =_.findIndex(state.data,{id:payload.id})
    state.data = ArrayUtil.removeItemById(state.data,payload.id);
})
        // add more case
      }
  })
  export const { fetchLessonReferences } = lessonsReferencesSlice.actions