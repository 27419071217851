import React from 'react';

const InputForm = ({className,title,type,value,onChange,field,errorMessage,style}) =>{
    
    return( <div className={'form-group' + (className ? ' ' + className : '')} style={style}>
    <label htmlFor={field} style={{color: '#68676a',letterSpacing:'-0.2px'}}>{title}</label>
    <input 
        type={type || 'text'}
        className={"form-control"}
        value={value} 
        onChange={({target})=>{onChange(target.value)}}
        id={field}
         />
          {errorMessage && <p style={{color:'red'}}>{errorMessage}</p>}
  </div>)
}

export default InputForm;