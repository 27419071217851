import React from "react";

export const PrivacyPolicyContent = () =>{

    return(<div style={{ animation: "fadeInAnimation ease 2s",
    animationIterationCount: 1,
    animationFillMode: "forwards"}}>
     <h2>Privacy Policy</h2>
        <p>
            The main purpose of our web site is to provide information and services to our visitors. Your privacy is important to us and we are committed to protecting it and to make you aware of our online practices regarding the privacy of your information.
        </p>
        <p>
            TorahConnect.com takes your privacy seriously. We created this privacy statement to demonstrate our firm commitment to your privacy. We promise that we will take steps to use your personal information only in ways that are compatible with this Privacy Policy.
        </p>
    </div>)
}