import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit,faTrash } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

export const FaqRow = ({item,onEdit,onRemove,index}) =>{
    return(   <tr style={{height:'5rem'}}><th scope="row">{item.id}</th>
    <td>{item.question}</td>
    <td>{item.answer}</td>
    <td>{item.topic}</td>
    <td style={{minWidth:'10rem'}}>
        <span style={{padding:'10px',boxShadow:'1px 1px 1px'}}>
         <Link to={"faqedit/" + item.id} >
            <FontAwesomeIcon icon={faEdit} />
            </Link>
        </span>
        <span style={{padding:'10px',boxShadow:'1px 1px 1px'}}>
            <FontAwesomeIcon icon={faTrash} onClick={()=>{onRemove(index);}} />
        </span>
    </td>
    </tr>
    )
}

