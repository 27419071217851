import React from "react";

export const LocationData = () =>{

    return(<div style={{ animation: "fadeInAnimation ease 2s",
    animationIterationCount: 1,
    animationFillMode: "forwards"}}>
     <h2>
            Location Data
        </h2>
        <p>
            Certain features ("Features") of our sites and/or apps may use data related to your geographic location ("Location Data"). If you use these features, you agree to provide or make your Location Data accessible to us. This information is used to provide you with localized information, such as local Halachic and holiday times or to show nearby events or locations. To the extent that we do collect Location Data, we shall use it in accordance with our Privacy Policy. If you do not provide or make such Location Data accessible then the Features may be limited or not operate.
        </p>
    </div>)
}