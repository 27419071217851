export const  errorMessages ={
 
    fieldRequired:'שדה חובה',
    chooseSelection:'בחר מהרשימה',
    emailField:'שדה האימייל הוא חובה והוא צריך להכיל כתובת מייל חוקית',
    passwordFieldError:'שדה הסיסמא הינו חובה והוא צריך להכיל תוים מיוחדים אותיות גדולות ואותיות קטנות',
    fileFieldError:'לא הועלה מסמך',
    phoneField:'שדה הטלפון הוא חובה והוא צריך להכיל מספר טלפון חוקי'
}

