import React,{useEffect,useState} from "react"
import { useSelector ,useDispatch} from "react-redux"
import { getActivities } from "../../redux/reducers/activities.reducer";
import THead from "../../components/tables/thead";
import { MezuzaActivityRow } from "../../components/tables/mezuzaActivity.row";
import { MezuzaActivityEditForm } from "../../components/forms/mezuza.activity.form";
import Modal from "../../components/modals/modal";
import { updateActivity } from "../../redux/reducers/activities.reducer";
import ButtonForm from "../../components/forms/button-form";
import { MezuzaActivityAddForm } from "../../components/forms/mezuza.addActivity.form";

export const MezuzaActivity = () =>{

    const dispatch = useDispatch();
    const activities = useSelector(state=>state.activities);
    const [shouldOpenEditModal,setShouldOpenEditModal] = useState(false);
    const [shouldOpenAddModal,setShouldOpenAddModal] = useState(false);
    const [selectedItem,setSelectedItem] = useState(null);

    useEffect(()=>{
        dispatch(getActivities())
    },[])

    const onSubmit = (item) =>{
        dispatch(updateActivity(item));
    }

    const onEdit = (index) =>{
        setSelectedItem(activities.data[index]);
        setShouldOpenEditModal(true);
    }
    const getActivitiesRows = ()=>{
        if(activities && activities.data){
            return activities.data.map((item,index)=><MezuzaActivityRow key={index} index={index} item={item} onEdit={onEdit} />)
        }
    }
    return(<>
    <div className="well">
        <ButtonForm text={'פתח בקשה'} onSubmit={()=>{setShouldOpenAddModal(true)}} />
    </div>
    <Modal shouldOpen={shouldOpenEditModal} onClose={()=>{setShouldOpenEditModal(false)}}>
        <MezuzaActivityEditForm onSubmit={onSubmit} item={selectedItem ? selectedItem.activityReport : null} />
    </Modal>
    <Modal shouldOpen={shouldOpenAddModal} onClose={()=>{setShouldOpenAddModal(false)}}>
        <MezuzaActivityAddForm onSubmit={onSubmit} />
    </Modal>
   <table className="table table-stripped"  style={{ width: '75%', margin: '10vh auto', fontSize: '1.6rem', background: '#fff', boxShadow: '1px 1px #888888' }}>
        <THead  headers={["מזהה", "שם הרבֿֿֿ/השליח", "שם המבקש", "דואֿ״ל", "טלפון", "וואצאפ",""]}
                headerColor={'rgb(215 237 253)'} />
                <tbody>
                    {getActivitiesRows()}
                </tbody>
    </table>
    </>)

}