import React, { useState } from "react";
import SelectInputForm from "../../components/forms/select-input-form";
import InputForm from "../../components/forms/input-form";

export const UsersFilters = () =>{

    const options = [{value: 0, label:'ללא פילטר' },{ value: 1, label: 'חיפוש לפי דוא״ל' }, { value: 2, label: 'חיפוש לפי שם' }]
   const [filter,setFilter] = useState('')

    const [value,setValue] = useState('')

    return( <div className="well" style={{ background: '#dde8ef', display: 'flex',gap:"1rem", flexDirection: 'row',margin:'1rem 3rem' ,alignItems:'center'}}>
    <div>
        <SelectInputForm
            options={options}
            onChange={({value})=>{setFilter(value)}}
        />
    </div>

   <InputForm onChange={setValue} value={value} />
   <button className="btn btn-primary">חפש</button>
</div>)
}