import React from "react";

export const ContactingUs = () =>{

    return(<div style={{ animation: "fadeInAnimation ease 2s",
    animationIterationCount: 1,
    animationFillMode: "forwards"}}>
      <h2>
        Contacting Us
        </h2>
        <p>
        if you have any questions about this privacy statement, the practices of this site, or your dealings with this Web site, please contact us online, or write to:<br />
TorahConnect.com<br />
845 Eastern Parkway <br />
Brooklyn, NY 11213.
        </p>
    </div>);
}