import React,{useState,useEffect} from 'react';
import { useDispatch} from 'react-redux';

import Autocomplete from './autocomplete/autocomplete';
import InputForm from './input-form';
import CheckboxForm from './checkbox-form';
import ButtonForm from './button-form';
import TextareaForm from './textarea-from';
import { UsersAutocomplete } from './autocomplete/usersAutocomplete';



export const NotificationForm = ({groups,item,onSubmit}) =>{
    const [group,setGroup]                     = useState(null);
    const [initialValue,setInitialValue]       = useState(null);
    const [isGroupMessage,setIsGroupMessage]   = useState(true);
    const dispatch                             = useDispatch();
    

    useEffect(()=>{
      if(item){
         setInitialValue(item.groupId)
         setGroup(item)
      }
    },[item])

   
    
 
   
   
    return(<form className="advertisement__item__tbl">
       {isGroupMessage &&<div className="advertisement__item__tbl-autocomplete">
       <label>בחר קבוצה</label>
        <div className="form-group" style={{ outline:'none'}}>
       
          <Autocomplete list={groups.data} onClick={setGroup} initialValue={initialValue} />
        </div>
        </div>}
        {!isGroupMessage &&<div className="advertisement__item__tbl-autocomplete">
       <label>בחר נמען</label>
        <UsersAutocomplete
        handleUserClicked={(e)=>{console.log(e)}}
        />
        </div>}
      <TextareaForm  />
         <CheckboxForm
            className="advertisement__item__tbl-online"
            name={'onlineOrder'}
            title={'קבוצתי או לא'}
            value={isGroupMessage}
            onChange={setIsGroupMessage}
         />
         <ButtonForm
            className="advertisement__item__tbl-btn"
            text="שלח"
            style={{width:'100%'}}
            onSubmit={(e)=>{onSubmit(e,group.id,isGroupMessage)}}
          />
    </form>)
}

