import React from "react";
import InputDateForm from "../../../components/forms/input-date-form";

export const LessonsFilterByDates = ({options,onChange}) =>{

    
    return(<div className="filter-by-dates">
        <InputDateForm field={'dateFrom'}/>
        <InputDateForm field={'dateTo'}/>
    </div>)
}