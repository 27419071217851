import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { axiosInstance} from "../../axiosInstance";
import ArrayUtil from "../../utils/array-util";
import {  toast } from 'react-toastify';
import { anauthorizedErrorHandler } from "../../handlers/error.handler";

const URL = "/api/v1/lessongivers"
export const getLessonslecturers = createAsyncThunk(
    'lessonslecturers/get',
    async () => {
      try {
       
        const response = await axiosInstance.getInstance().get(URL)
     
        return response.data
      } catch (err) {
        anauthorizedErrorHandler(err);
       
      }
    }
  )

  export const addLessonGiver = createAsyncThunk(
    'lessonslecturers/addLessonGiver',
    async ({data,userId}) => {
      try {
       
        const response = await axiosInstance.getInstance().post(`${URL}/${userId}`,data)
     
        return response.data
      } catch (err) {
        anauthorizedErrorHandler(err);
      }
    }
  )
 
  

  export const lessonsLecturersSlice = createSlice({
    name: "lessonsLecturers",
    initialState: {
        data: [],
      loading:false
    },
    reducers: {
    fetchLessonsLecturers(state, action) {
        state.data = action.payload
      }
    },
    extraReducers: (builder) => {
        builder.addCase(getLessonslecturers.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.data = payload;
         
        })
        builder.addCase(getLessonslecturers.pending, (state, { payload }) => {
            state.loading = true;
        })
        builder.addCase(getLessonslecturers.rejected, (state, { payload }) => {
            state.loading = false;
          //  state.data = [];
        })
        builder.addCase(addLessonGiver.fulfilled, (state, { payload }) => {
          state.loading = false;
          //state.data = payload;
       
      })
      builder.addCase(addLessonGiver.pending, (state, { payload }) => {
          state.loading = true;
      })
      builder.addCase(addLessonGiver.rejected, (state, { payload }) => {
          state.loading = false;
        //  state.data = [];
      })
     
      
        // add more case
      }
  })
  export const { fetchLessonsLecturers } = lessonsLecturersSlice.actions