import React,{useState,useEffect, useContext} from 'react';
import {useDispatch,useSelector} from 'react-redux';

import { doLogin,doSignup } from '../redux/reducers/auth-reducer';
import SignupModal from './../components/modals/signup-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {  } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom';
import { RouteContext } from '../contexts/route.context';
import { pages } from '../utils/pages.util';
import ValidatorUtil from '../utils/validator-util';

const Login = () =>{

    const dispatch                      = useDispatch();
    const auth                          = useSelector(state=>state.auth);
    const [email,setEmail]              = useState('');
    const [password,setPassword]        = useState('');
    const [shouldShowSignupModal,setShouldShowSignupModal] = useState(false);
    const [showEmailError,setShowEmailError] = useState(false);
    const [showPassError,setShowPassError] = useState(false);
    const onRouteChanged = useContext(RouteContext).onRouteChanged;

    const onOpenSignupModalClicked = () =>{
        setShouldShowSignupModal(true);
    }
    
    const onLoginClicked = (e) =>{
        e.preventDefault();
        let isFormValid = true;
        if(!ValidatorUtil.isEmailValid(email)){
            isFormValid = false;
            setShowEmailError(true)
        }else{
            setShowEmailError(false);
        }
        if(!ValidatorUtil.hasValue(password) ){
            isFormValid = false;
            setShowPassError(true);
        }else{
            setShowPassError(false);
        }
        if(isFormValid){
          
          
            dispatch(doLogin({email,password}))
        }
       
    }

    useEffect(()=>{
        if(auth){
            setShouldShowSignupModal(false);
        }
        onRouteChanged(pages['home']);
    },[auth])

   


    return(<div style={{backgroundImage:'url(770Lubavitch.jpeg)',backgroundRepeat:'no-repeat',backgroundSize:'cover',display:'flex',justifyContent:'center',alignItems:'center',height:'100%'}}>
        <div className="login panel" style={{width:'30rem',margin:'0 auto',position:'relative',display: 'flex',flexDirection: 'column'}}>
      
    <SignupModal shouldOpen={shouldShowSignupModal} onClose={()=>{setShouldShowSignupModal(false)}} />
    <h1 style={{fontFamily:'"Open Sans"',fontStyle:'italic',color:'#5a5556',textAlign:'center'}}>
        <img style={{width:'15rem'}} src="./images/torahconnect-high-resolution-logo-black-on-transparent-background.png" />
    </h1>
    
    <div className="panel-heading" style={{fontSize:'1.4rem',textAlign:'center'}}>
   
    </div>
    <div className="panel-body">
        <form>
            <fieldset>
                <div className="form-group">
                    <input className="form-control login-input" 
                        placeholder="Email" 
                        value={email}  
                        type="email" 
                        onChange={({target})=>{setEmail(target.value)}}
                        />
                        
                </div>
                <div className="form-group">
                    <input className="form-control login-input" 
                        placeholder="Password" 
                        value={password} 
                        type="password" 
                        onChange={({target})=>{setPassword(target.value)}} 
                        />
                        
                </div>
               {showEmailError && <p style={{marginTop:-15,marginBottom:15,alignSelf:'flex-start',color:'red'}}>Email is not valid</p>}
                {showPassError &&<p style={{marginTop:-15,marginBottom:15,alignSelf:'flex-start',color:'red'}}>Password is required</p>}
                <button style={{width:'100%'}} className="btn tc-btn" onClick={onLoginClicked}>Login</button></fieldset>
        </form>
       <span style={{    fontFamily: 'Lato',display:'inline-block', marginTop: "5px",color: "#757070"}}>To join to torah lesson please&nbsp;<a className='login-input' onClick={onOpenSignupModalClicked} >Register</a></span> 
    </div>
    <Link to={"/privacy"} style={{display:'block',margin:'0 auto', fontSize: "11px"}}>Privacy Policy</Link>
</div></div>)
}

export default Login