import React, { useState, useEffect } from 'react';
import InputForm from './input-form';
import ButtonForm from './button-form';
import SelectInputForm from './select-input-form';
import { UsersAutocomplete } from './autocomplete/usersAutocomplete';
import CheckboxFrom from './checkbox-form';
import ValidatorUtil from '../../utils/validator-util';
import { errorMessages } from '../../utils/error-messages-util';




const statusOptions = [{ value: 0, label: 'נפתחה בקשה' }, { value: 1, label: 'בתהליך' }, { value: 2, label: 'טופל' }]


export const MezuzaActivityAddForm = ({ item, onSubmit }) => {


    const [isUserExist, setIsUserExist] = useState(false);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [firstNameError, setFirstNameError] = useState('');
    const [lastNameError, setLastNameError] = useState('');
    const [emailError, setEmailError] = useState('');
    const [phoneError, setPhoneError] = useState('');

    const isFormValid = () =>{
        let result = true;
        if(ValidatorUtil.hasValue(firstName)){
            setFirstNameError('')
        }else{
            setFirstNameError(errorMessages.fieldRequired)
            result = false;
        }
        if(ValidatorUtil.hasValue(lastName)){
            setLastNameError('')
        }else{
            setLastNameError(errorMessages.fieldRequired)
            result = false;
        }
        if(ValidatorUtil.hasValue(email) && ValidatorUtil.isEmailValid(email)){
            setEmailError('')
        }else{
            setEmailError(errorMessages.emailField)
            result = false;
        }
        if(ValidatorUtil.hasValue(phone) && ValidatorUtil.isPhoneValid(phone)){
            setPhoneError('')
        }else{
            setPhoneError(errorMessages.phoneField)
            result = false;
        }

        return result;
    }
    const onSubmitClicked = (e) =>{
        e.preventDefault();
        if(isFormValid()){
            onSubmit({firstName,lastName,phone,email})
        }
    }


    return (<form style={{ display: 'grid', gridTemplateRows: '5rem 1fr', gap: '.5rem',width:'30rem' }}>
           <div>
            <CheckboxFrom onChange={setIsUserExist} title={' '+'?האם המשתמש קיים'} value={isUserExist} />
        </div>
        {isUserExist && <div>
            <UsersAutocomplete handleUserClicked={() => { }} />
        </div>}
        {!isUserExist && <div>
            <InputForm title={'הזן שם פרטי'} value={firstName} onChange={setFirstName} errorMessage={firstNameError} />
            <InputForm title={'הזן שם משפחה'} value={lastName} onChange={setLastName} errorMessage={lastNameError} />
            <InputForm title={'הזן טלפון '} value={phone} onChange={setPhone} errorMessage={phoneError} />
            <InputForm title={'הזן דוא\"ל '} value={email} onChange={setEmail} errorMessage={emailError} />
        </div>}
     

        <span style={{ gridColumnStart: 1, gridColumnEnd: -1, textAlign: 'center' }}>
            <ButtonForm text={'עדכן'} onSubmit={onSubmitClicked} />
        </span>

    </form>)
}