import ArrayUtil from '../../utils/array-util';

import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { axiosInstance} from "../../axiosInstance";

const BASE_URL = "/api/v1/notifications"

export const getNotifications = createAsyncThunk(
    'notifications/get',
    async () => {
      try {
        const response = await axiosInstance.getInstance().get(BASE_URL)
      
        return response.data
      } catch (err) {
        console.log(err)
        return "error"
      }
    }
  )

  

  

  export const addNotification = createAsyncThunk(
    'notifications/add',
    async (notification) => {
      try {
        const response = await axiosInstance.getInstance().post(BASE_URL,notification)
      
        return response.data
      } catch (err) {
        console.log(err)
      }
    }
  )
  export const updateNotification = createAsyncThunk(
    'notifications/update',
    async (notification) => {
      try {
        const response = await axiosInstance.getInstance().put(BASE_URL + "/" + notification.id ,notification)
      
        return response.data
      } catch (err) {
        console.log(err)
      }
    }
  )

export const notificationsSlice = createSlice({
    name: "notifications",
    initialState: {
      data: [],
      loading:false
    },
    reducers: {
    fetchUsers(state, action) {
        state.data = action.payload
      }
    },
    extraReducers: (builder) => {
        builder.addCase(getNotifications.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.data = payload;
        })
        builder.addCase(getNotifications.pending, (state, { payload }) => {
            state.loading = true;
        })
        builder.addCase(getNotifications.rejected, (state, { payload }) => {
            state.loading = false;
        })
        builder.addCase(addNotification.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.data = [...state.data,payload];
        })
        builder.addCase(addNotification.pending, (state, { payload }) => {
            state.loading = true;
        })
        builder.addCase(addNotification.rejected, (state, { payload }) => {
            state.loading = false;
        })
        builder.addCase(updateNotification.fulfilled, (state, { payload }) => {
          state.loading = false;
          const arr = ArrayUtil.updateById(state.data,payload)
          state.data = [...arr];
      })
      builder.addCase(updateNotification.pending, (state, { payload }) => {
          state.loading = true;
      })
      builder.addCase(updateNotification.rejected, (state, { payload }) => {
          state.loading = false;
      })
        // add more case
      }
  })
  export const { fetchUsers } = notificationsSlice.actions


const getUpdatedArray = (arr,obj) =>{
    const index = ArrayUtil.getIndexById(arr,{id:obj.id});
   arr[index] = obj;
   return [...arr];
 }

