import React,{useEffect,useState} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit,faTrash } from '@fortawesome/free-solid-svg-icons';
import {Link} from 'react-router-dom'
import { contactType } from '../../enums/contact.type';

const ContactRow = (props) =>{

    const [departmentName,setDepartmentName] = useState('');
    const {departments,item} = props;
    
    return(   <tr><th scope="row" style={{height:'5rem'}}>{props.item.id}</th>
    <td>{item.rabbi.name}</td>
    <td>{item.content}</td>
    <td>{item.user.name}</td>
    <td>{contactType[item.contactType]}</td>
 
    <td><Link to={'notifydrivers/' + item.id} >שלח מסר</Link></td>
    <td style={{display:'flex',flexDirection:'row'}}>
        <span style={{padding:'10px',boxShadow:'1px 1px 1px'}} onClick={()=>{props.onEdit(props.index);}}>
            <FontAwesomeIcon icon={faEdit} />
        </span>
        <span style={{padding:'10px',boxShadow:'1px 1px 1px'}} onClick={()=>{props.onRemove(props.index);}}>
            <FontAwesomeIcon icon={faTrash} />
        </span>
    </td>
    </tr>
    )
}

export default ContactRow;