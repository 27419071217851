import React,{useState} from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from '@fortawesome/free-solid-svg-icons';

export const KosherizationActivityRow = ({item,onEdit,index}) =>{

    const [showDetails,setShowDetails] = useState(false);

    return(<><tr style={{height:'5rem'}} onClick={()=>{setShowDetails(!showDetails)}}>
        <td>{item.id}</td>
        <td>{item.user.firstName + ' ' + item.user.lastName}</td>
        <td>{item.rabbi.firstName + ' ' + item.rabbi.lastName}</td>
        <td>{item.user.email}</td>
        <td>{item.user.phone}</td>
        <td>{item.user.whatsapp}</td>
        <td>
         
            </td>
        </tr>
       {showDetails && <tr>
            <td colSpan={6} style={{padding:'2rem .5rem'}} >
                
            <div style={{textAlign:'right',display:'flex',flexDirection:'row-reverse' ,flexWrap:'wrap',gap:'3.5rem'}}>
                <div>{(item.activityReport.address || '') } <label>{":כתובת"}</label></div>

            </div>
            <div style={{textAlign:'right',display:'flex',flexDirection:'row-reverse' ,flexWrap:'wrap',gap:'3.5rem'}}>
            <div>{(item.activityReport.status || 0)} <label>{":סטטוס"}</label></div>
            <div><table>
                <tbody>
                    <tr>
                        <td>{item.relatedLessons &&<ul>{item.relatedLessons.map((lesson,index)=>{
                            return <li key={index}>{lesson}</li>
                        })}</ul>}</td>
                        <td><label>{":למד על"}</label></td>
                    </tr>
                </tbody>
                </table></div>
                <div>{(item.activityReport.remark || 0)} <label>{":הערות"}</label></div>
            </div>
          
            </td>
            <td>
            <span style={{padding:'10px'}}>
            <FontAwesomeIcon icon={faEdit} onClick={()=>{onEdit(index);}} />
        </span>
            </td>
        </tr>}</>)
}