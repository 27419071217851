import React from "react";
import THead from "../../components/tables/thead";
import LessonParticipantRow from "../../components/tables/lessonParticipantRow";

const LessonParticipantsTable = ({items}) =>{

    const getLessonParticipants = () =>{
        if(items){
            return items.map((item,index)=><LessonParticipantRow key={index} item={item}  />)
        }
    }
    return(<table className="table table-stripped" style={{ width: '75%', margin: '5vh auto', fontSize: '1.6rem', background: '#fff' }}>
    <THead
        headers={["מזהה", "שם", "דואֿ״ל", ""]}
    />
    <tbody>
        {getLessonParticipants()}
    </tbody>
</table>)
}

export default LessonParticipantsTable