import React from "react";

import { InfoListItem } from "./info-list-item";

export const InfoList = ({onClick}) =>{

    return(<ul className="info-list">
<InfoListItem title="Privacy Policy" value="privacyPolicyContent" onClick={onClick}/>
<InfoListItem title="Information About You" value="aboutYou" onClick={onClick} />
<InfoListItem title={"Cookies"} value="cookies" onClick={onClick} />
<InfoListItem title={"Location Data"} value="locationData" onClick={onClick} />
<InfoListItem title={"Links"} value="links" onClick={onClick} />
<InfoListItem title={"Feedback & Ask the Rabbi"} value="askTheRabbi" onClick={onClick} />
<InfoListItem title={"Security"} value="security" onClick={onClick}/>
<InfoListItem title={"Contating Us"} value="contactingUs" onClick={onClick}/>
<InfoListItem title={"Terms of Service"} value="termsOfService" onClick={onClick} />
    </ul>)
} 