import React from 'react'

export const TermsOfService = () =>{

    return(<div style={{ animation: "fadeInAnimation ease 2s",
    animationIterationCount: 1,
    animationFillMode: "forwards"}}>
            <h1>
        Terms of Service
        </h1>
        <h3 style={{boxSizing:"inherit",marginTop:0,marginBottom:"0.5rem",fontFamily:"arial",fontSize:"16px",color:"rgb(16,86,137)"}}>1. Your Acceptance</h3>
        <ol style={{boxSizing:"inherit"}}>
            <li style={{boxSizing:"inherit",fontSize:"1.7rem",lineHeight:"30.6px"}}>By using or visiting the&nbsp;<span style={{fontSize:"1.7rem"}}>TorahConnect.com</span>&nbsp;website or any&nbsp;<span style={{fontSize:"1.7rem"}}>TorahConnect.com</span>&nbsp;products, software, data feeds, and services provided to you on, from, or through the&nbsp;<span style={{fontSize:"1.7rem"}}>TorahConnect.com</span>&nbsp;websites (collectively the "Service") you indicate your agreement to (1) these terms and conditions (the "Terms of Service"), and (2)&nbsp;
<span style={{fontSize:"1.7rem"}}>TorahConnect.com</span>'s privacy notice, found at&nbsp;<a href="https://www.chabad.org/privacy" style={{boxSizing:"inherit",color:"inherit",backgroundColor:"transparent"}} target="_blank" data-saferedirecturl="https://www.google.com/url?q=https://www.chabad.org/privacy&amp;source=gmail&amp;ust=1677683177656000&amp;usg=AOvVaw2IKoU6QFlZwBOuNIg-ACjx">www.<span style={{fontSize:"1.7rem"}}>TorahConnect.com</span>&nbsp; /privacy</a>&nbsp;and incorporated herein by reference. If you do not agree to any of these terms or the&nbsp;
<span style={{fontSize:"1.7rem"}}>TorahConnect.com</span>&nbsp;privacy notice please do not use the Service.</li>
<li style={{boxSizing:"inherit",fontSize:"1.7rem",lineHeight:"30.6px"}}>Although we may attempt to notify you when changes are made to these Terms of Service, you should periodically review the most up-to-date version.&nbsp;

<span style={{fontSize:"1.7rem"}}>TorahConnect.com</span>&nbsp;may, in its sole discretion, modify or revise these Terms of Service and policies at any time, and you agree to be bound by such modifications or revisions. Nothing in these Terms of Service shall be deemed to confer any third-party rights or benefits.</li>
</ol>
<h3 style={{boxSizing:"inherit",marginTop:0,marginBottom:"0.5rem",fontFamily:"arial",fontSize:"16px",color:"rgb(16,86,137)"}}>2. Service</h3>
<ol style={{boxSizing:"inherit"}}>
    <li style={{boxSizing:"inherit",fontSize:"1.7rem",lineHeight:"30.6px"}}>These Terms of Service apply to all users of the Service, including users who are also contributors of Content on the Service. “Content” includes the text, software, scripts, graphics, photos, sounds, music, videos, audiovisual combinations, interactive features and other materials you may view on, access through, or contribute to the Service. The Service includes all aspects of&nbsp;

<span style={{fontSize:"1.7rem"}}>TorahConnect.com</span>, including but not limited to all products, software and services offered via the Service.</li>
<li style={{boxSizing:"inherit",fontSize:"1.7rem",lineHeight:"30.6px"}}>The Service may contain links to third-party websites that are not owned or controlled by&nbsp;

<span style={{fontSize:"1.7rem"}}>TorahConnect.com</span>.&nbsp;

<span style={{fontSize:"1.7rem"}}>TorahConnect.com</span>&nbsp;has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third party websites. In addition,&nbsp;

<span style={{fontSize:"1.7rem"}}>TorahConnect.com</span>&nbsp;will not and cannot censor or edit the content of any third-party site. By using the Service, you expressly relieve&nbsp;

<span style={{fontSize:"1.7rem"}}>TorahConnect.com</span>&nbsp;of any and all liability arising from your use of any third-party website.</li><li style={{boxSizing:"inherit",fontSize:"1.7rem",lineHeight:"30.6px"}}>Accordingly, we encourage you to be aware when you leave the Service to read the terms and conditions and privacy policy of each website that you visit.</li>
</ol>
       <h3 style={{boxSizing:"inherit",marginTop:0,marginBottom:"0.5rem",fontFamily:"arial",fontSize:"16px",color:"rgb(16,86,137)"}}>3. General Use of the Service—Permissions and Restrictions</h3> 
<p>
TorahConnect.com hereby grants you permission to access and use the Service as set forth in these Terms of Service, provided that:
</p>
<ol style={{boxSizing:"inherit"}}>
    <li style={{boxSizing:"inherit",fontSize:"1.7rem",lineHeight:"30.6px"}}>You agree not to distribute in any medium or format any part of the Service or the Content without&nbsp;

<span style={{fontSize:"1.7rem"}}>TorahConnect.com</span>'s prior express written authorization, unless Chabad.org makes available the means for such distribution through functionality offered by the Service (such as the Embeddable Player).</li>

<li style={{boxSizing:"inherit",fontSize:"1.7rem",lineHeight:"30.6px"}}>You agree not to alter or modify any part of the Service.</li>

<li style={{boxSizing:"inherit",fontSize:"1.7rem",lineHeight:"30.6px"}}>You agree not to use the Service for any of the following commercial uses unless you obtain&nbsp;<span style={{fontSize:"1.7rem"}}>TorahConnect.com</span>'s prior written approval:<ul style={{boxSizing:"inherit"}}><li style={{boxSizing:"inherit",fontSize:"1.7rem",lineHeight:"30.6px"}}>the sale of access to the Service;</li><li style={{boxSizing:"inherit",fontSize:"1.7rem",lineHeight:"30.6px"}}>the sale of advertising, sponsorships, or promotions placed on or within the Service or Content; or</li><li style={{boxSizing:"inherit",fontSize:"1.7rem",lineHeight:"30.6px"}}>the sale of advertising, sponsorships, or promotions on any page of an ad-enabled blog or website containing Content delivered via the Service, unless other material not obtained from&nbsp;

<span style={{fontSize:"1.7rem"}}>TorahConnect.com</span>&nbsp;appears on the same page and is of sufficient value to be the basis for such sales.</li></ul></li><li style={{boxSizing:"inherit",fontSize:"1.7rem",lineHeight:"30.6px"}}>You agree not to use or execute any automated system, including without limitation, "robots," "spiders," or "offline readers," that accesses the Service in a manner that sends more request messages to the&nbsp;

<span style={{fontSize:"1.7rem"}}>TorahConnect.com</span>&nbsp;servers in a given period of time than a human can reasonably produce in the same period by using a conventional online web browser. Notwithstanding the foregoing,&nbsp;

<span style={{fontSize:"1.7rem"}}>TorahConnect.com</span>&nbsp;grants the operators of public search engines permission to use spiders to copy materials from the site for the sole purpose of and solely to the extent necessary for creating publicly available searchable indices of the materials, but not caches or archives of such materials.&nbsp;

<span style={{fontSize:"1.7rem"}}>TorahConnect.com</span>&nbsp;reserves the right to revoke these exceptions either generally or in specific cases. You agree not to collect or harvest any personally identifiable information, including account names, from the Service, nor to use the communication systems provided by the Service (e.g., comments, email) for any commercial solicitation purposes. You agree not to solicit, for commercial purposes, any users of the Service with respect to their Content.</li>
<li style={{boxSizing:"inherit",fontSize:"1.7rem",lineHeight:"30.6px"}}>In your use of the Service, you will comply with all applicable laws.</li>
<li style={{boxSizing:"inherit",fontSize:"1.7rem",lineHeight:"30.6px"}}><span style={{fontSize:"1.7rem"}}>TorahConnect.com</span>&nbsp;reserves the right to discontinue any aspect of the Service at any time.</li>
</ol>
<h3 style={{boxSizing:"inherit",marginTop:0,marginBottom:"0.5rem",fontFamily:"arial",fontSize:"16px",color:"rgb(16,86,137)"}}>
4. Your Use of Content
</h3>
<p>
In addition to the general restrictions above, the following restrictions and conditions apply specifically to your use of Content.
</p>
<ol style={{boxSizing:"inherit"}}><li style={{boxSizing:"inherit",fontSize:"1.7rem",lineHeight:"30.6px"}}>The Content on the Service, and the trademarks, service marks and logos ("Marks") on the Service, are owned by or licensed to&nbsp;

<span style={{fontSize:"1.7rem"}}>TorahConnect.com</span>, subject to copyright and other intellectual property rights under the law.</li><li style={{boxSizing:"inherit",fontSize:"1.7rem",lineHeight:"30.6px"}}>Content is provided to you AS IS. You may access Content for your information and personal use solely as intended through the provided functionality of the Service and as permitted under these Terms of Service. You shall not download any Content unless you see a “download” or similar link displayed by&nbsp;

<span style={{fontSize:"1.7rem"}}>TorahConnect.com</span>&nbsp;on the Service for that Content. You shall not copy, reproduce, distribute, transmit, broadcast, display, sell, license, or otherwise exploit any Content for any other purposes without the prior express written consent of&nbsp;

<span style={{fontSize:"1.7rem"}}>TorahConnect.com</span>&nbsp;or the respective licensors of the Content.&nbsp;

<span style={{fontSize:"1.7rem"}}>TorahConnect.com</span>&nbsp;and its licensors reserve all rights not expressly granted in and to the Service and the Content.</li><li style={{boxSizing:"inherit",fontSize:"1.7rem",lineHeight:"30.6px"}}>You agree not to circumvent, disable or otherwise interfere with security-related features of the Service or features that prevent or restrict use or copying of any Content or enforce limitations on use of the Service or the Content therein.</li></ol>
    </div>)
}