import React from "react";

export const AboutYou = ()=>{
    return(<div style={{ animation: "fadeInAnimation ease 2s",
    animationIterationCount: 1,
    animationFillMode: "forwards"}}>
       
        <h2 style={{fontFamily:'Lato',fontWeight:600}}>
            Information About You
        </h2>
        <p>
            When you voluntarily provide it, we collect personal information in the course of interacting with the site. Contact information, such as name, email and phone number, and demographic information, such as zip code, may be gathered from our visitors from information requests or other forms.
        </p>
        <p>
            We may use the information you give us to provide services to you, at your request. We may use it to communicate with you through periodic messages regarding services, events, and other information and notices. We may use it for confirmation of donations, registrations, or other actions you take on our site, and for related customer service needs.  We only use contact information to send information requested or to contact users as necessary.
        </p>
        <p>
            We do not sell, rent, swap or otherwise share our mailing lists with external organizations. Any personal information you provide to  TorahConnect.com  will not be disseminated to third parties outside of our organization.
        </p>
        <p>
            We also may collect information that is not personally identifiable, such as the type of browser you are using (e.g., Netscape, Internet Explorer), the type of operating system you are using, (e.g., Windows or Mac OS), the IP address (Internet Protocol set of numbers called Internet Address) you are using, and the domain name of your Internet service provider (e.g. Verizon, Comcast). We may use this information to analyze the design and functionality of our site in order to make site improvements. We may use such information in the aggregate to analyze site usage.
        </p>
        <p>
            Our Web site does not automatically collect any individually identifiable information, such as email addresses, from visitors.
        </p>
        <p>
            We pledge that we will never willfully sell, trade, rent, disclose, or make available personally identifiable information about you to any third party without first receiving your permission, except when we believe in good faith that the law requires it, or to protect the rights or property of  TorahConnect.com.
        </p>
        <p>
            You have the right under this Privacy Policy, and by law if You are within the EU, to:
        </p>
        <ul style={{boxSizing:"inherit",color:"inherit",fontFamily:"Arial",fontSize:"14px"}}>
            <li style={{boxSizing:"inherit",fontSize:"1.7rem",lineHeight:"30.6px"}}><strong style={{boxSizing:"inherit"}}>Request access to Your Personal Data.</strong>&nbsp;The right to access, update or delete the information We have on You. If you are unable to perform these actions yourself, please contact Us to assist You.</li>
            <li style={{boxSizing:"inherit",fontSize:"1.7rem",lineHeight:"30.6px"}}><strong style={{boxSizing:"inherit"}}>Request correction of the Personal Data that We hold about You.</strong>&nbsp;You have the right to have any incomplete or inaccurate information We hold about You corrected.</li>
            <li style={{boxSizing:"inherit",fontSize:"1.7rem",lineHeight:"30.6px"}}><strong style={{boxSizing:"inherit"}}>Object to processing of Your Personal Data.</strong>&nbsp;This right exists where We are relying on a legitimate interest as the legal basis for Our processing and there is something about Your particular situation, which makes You want to object to our processing of Your Personal Data on this ground. You also have the right to object where We are processing Your Personal Data for direct marketing purposes.</li>
            <li style={{boxSizing:"inherit",fontSize:"1.7rem",lineHeight:"30.6px"}}><strong style={{boxSizing:"inherit"}}>Request erasure of Your Personal Data.</strong>&nbsp;You have the right to ask Us to delete or remove Personal Data when there is no good reason for Us to continue processing it.</li>
            <li style={{boxSizing:"inherit",fontSize:"1.7rem",lineHeight:"30.6px"}}><strong style={{boxSizing:"inherit"}}>Withdraw Your consent.</strong>&nbsp;You have the right to withdraw Your consent on using your Personal Data. If You withdraw Your consent, We may not be able to provide You with access to certain specific functionalities of the Service.</li>
        </ul>
        <p>
            If you want to discuss any matter relating to how your data is used, please call us, email us or write to the Data Protection Officer at feedback@TorahConnect.com. In some cases we may be obliged to ask that a formal request be submitted in writing.
        </p>
    </div>)
}