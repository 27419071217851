import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import InputForm from './input-form';
import ButtonForm from './button-form';
import { UsersAutocomplete } from './autocomplete/usersAutocomplete';
import SelectInputForm from './select-input-form';
import { Days } from '../constants/days.const';
import TimePicker from "react-time-picker";
import { HebrewCalendar } from '@hebcal/core';
import { Languages } from "../constants/languages.consts";
import ValidatorUtil from '../../utils/validator-util';
import { errorMessages } from '../../utils/error-messages-util';
import { addLessonSchedule } from '../../redux/reducers/weeklyLessons-reducer';

let languagesOptions = [];
let daysOptions = [];
let weeks = [];
let events = [];
export const WeeklyLessonForm = ({ item }) => {

   const [id, setId] = useState(null);
   const [time, setTime] = useState('');
   const [dayInWeek, setDayInWeek] = useState('');
   const [beginingWeek, setBeginingWeek] = useState('');
   const [date, setDate] = useState('');
   const [rabbiId, setRabbiId] = useState(null);
   const [rabbiIdError, setRabbiIdError] = useState('');
   const [userId, setUserId] = useState(null);
   const [userIdError, setUserIdError] = useState('');
   const [title, setTitle] = useState(null);
   const [titleError, setTitleError] = useState('');
   const [language, setLanguage] = useState(0)
   const dispatch = useDispatch();


   const onSubmit = (e) => {
      e.preventDefault();
      if (isFormValid()) {
         const hours = + time.split(':')[0];
         const minutes = + time.split(':')[1];
         // const d = new Date(beginingWeek.getFullYear(), beginingWeek.getMonth(), beginingWeek.getDate())
         // d.setHours(hours);
         // d.setMinutes(minutes);
         // d.setDate(d.getDate() + dayInWeek.value);
         // setDate(d)
         const obj = { time, userRabbiId:rabbiId, userId,languageId:language.value, title, dayInWeek: dayInWeek.value,remark:''};
       //  dispatch(addLessonSchedule(obj))
         console.log(obj)
      }
   }

   const isFormValid = () => {
      let result = true;

      if (!ValidatorUtil.hasValue(title)) {
         setTitleError(errorMessages.fieldRequired);
         result = false;
      } else {
         setTitleError('')
      }
      if (userId === null) {
         setUserIdError(errorMessages.fieldRequired);
         result = false;
      } else {
         setUserIdError('')
      }

      if (rabbiId === null) {
         setRabbiIdError(errorMessages.fieldRequired);
         result = false;
      } else {
         setTitleError('')
      }

      return result;
   }

   useEffect(() => {
      languagesOptions = Object.keys(Languages).map((key) => { return { value: Languages[key].value, label: Languages[key].label } })
      weeks = [];
      events = HebrewCalendar.calendar({ sedrot: true, noHolidays: true, locale: 'he', start: new Date(2022, 8, 26), end: new Date(2023, 8, 16) });

      events.forEach((e, i) => {
         weeks.push({ value: i, label: e.render('he-x-NoNikud') })
      })
      daysOptions = Object.keys(Days).map((key) => { return { value: Days[key].value, label: Days[key].label } })
      setDayInWeek(daysOptions[0])
   }, [])
   return (<form style={{ width: '40rem', margin: '0 auto' }}>
      <InputForm
         name={'title'}
         title={'הזן כותרת'}
         value={title}
         onChange={setTitle}
         errorMessage={titleError}
      />
      <label style={{ color: '#68676a', letterSpacing: '-0.2px' }}>{'הזן שעה'}</label>
      <TimePicker onChange={setTime} value={time} />
      {/* <SelectInputForm
                        title={'בחר שבוע'}
                        options={weeks}
                        onChange={(e) => { 
                           const hDate = events[e.value].getDate()
                           const str = '' + hDate.getFullYear() + hDate.month + hDate.day;
                           const weekNumber  = +str;

                            setBeginingWeek(events[e.value].getDate().greg())
                           // const hours = +lessonData.time.split(':')[0];
                          //  const minutes = +lessonData.time.split(':')[1];
                           // date.setHours(hours);
                          //  date.setMinutes(minutes);
                          //  date.setDate(date.getDate() + lessonData.dayInWeek)
                          //  setDate(date)
                        }}
                    /> */}
      <SelectInputForm
         title={'הזן יום בשבוע'}
         options={daysOptions}
         value={dayInWeek}
         onChange={setDayInWeek}
      />


      <div className="form-group" style={{ marginTop: '3px' }}>
         <label style={{ color: '#68676a', letterSpacing: '-0.2px' }}>{'הזן שם הרב'}</label>
         <UsersAutocomplete initialValue={item ? { name: item.user.firstName + ' ' + item.user.lastName, value: item.userId } : ''}
            handleUserClicked={(e) => { setRabbiId(e.value) }} />
         {rabbiIdError && <p style={{ color: 'red' }}>{rabbiIdError}</p>}
      </div>
      <div className="form-group" style={{ marginTop: '3px' }}>
         <label style={{ color: '#68676a', letterSpacing: '-0.2px' }}>{'הזן שם המשתתף'}</label>
         <UsersAutocomplete initialValue={item ? { name: item.user.firstName + ' ' + item.user.lastName, value: item.userId } : ''}
            handleUserClicked={(e) => { setUserId(e.value) }} />
         {userIdError && <p style={{ color: 'red' }}>{userIdError}</p>}
      </div>

      <SelectInputForm title={'בחר שפה'} options={languagesOptions} value={language} onChange={setLanguage} />
      <ButtonForm
         text="עדכן"
         onSubmit={onSubmit}
         style={{ width: '100%' }}
      />
   </form>)

}

