import React from 'react';

const TextareaForm = ({onChange,value,className,field,id,title,labelStyle}) =>{

    return(  <div className={'form-group' + (className ? ' ' + className : '')}>
    <label style={labelStyle} htmlFor={field}>{title}</label>
    <textarea 
            className={"form-control"}
            style={{resize:'vertical'}}
            onChange={({target})=>{onChange(target.value)}} 
            value={value} 
            id={id} 
            rows="3"></textarea></div>)
}

export default TextareaForm;

 