import React, { useState, useEffect } from 'react';

import FormInput from "./input-form";
import SelectInputForm from './select-input-form';
import ArrayUtil from '../../utils/array-util';
import ValidatorUtil from '../../utils/validator-util';
import Checkbox from './checkboxes';
import { errorMessages } from '../../utils/error-messages-util';

const UserForm = (props) => {

  const { item } = props;
  const [firstName, setFirstName] = useState('');
  const [firstNameError, setFirstNameError] = useState(null);
  const [lastName, setLastName] = useState('');
  const [lastNameError, setLastNameError] = useState(null);
  const [phone, setPhone] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const [whatsapp, setWhatsapp] = useState('');
  const [password, setPassword] = useState('');
  const [passwordErrror, setPasswordError] = useState(null);
  const [email, setEmail] = useState('')
  const [emailError, setEmailError] = useState(null)
  const [gender, setGender] = useState('')
  const [roles, setRoles] = useState([]);
  const options = [{ value: 'male', label: 'male' }, { value: 'female', label: 'female' }];
  const roleOptions = [{ value: 'admin', label: 'admin' }, { value: 'user', label: 'user' }];



  useEffect(() => {

    if (item) {
      setFirstName(item.firstName);
      setLastName(item.lastName);
      setEmail(item.email);
      setPhone(item.phone);
      setWhatsapp(item.whatsapp || item.phone);
      setGender(item.gender);
    }

  }, [item])



const updateRoles = (permission,state) =>{
  const _roles = roles;
  if(state){
    _roles.push({name:permission,userId:1})
  }else{
    const newRoles = ArrayUtil.removeItemByField(_roles,permission,'name')
    setRoles(newRoles);
  }
}

  const onSubmit = (e) => {
    e.preventDefault();
    let hasError = false;
    if (!ValidatorUtil.hasValue(firstName)) {
      setFirstNameError(errorMessages.fieldRequired);
      hasError = true;
    }else{
      setFirstNameError(null)
    }
    if (!ValidatorUtil.hasValue(lastName)) {
      setLastNameError(errorMessages.fieldRequired);
      hasError = true;
    }else{
      setLastNameError(null)
    }
    if (!ValidatorUtil.isPasswordValid(password)) {
      setPasswordError(errorMessages.passwordFieldError);
      hasError = true;
    }else{
      setPasswordError(null)
    }
    if (!ValidatorUtil.isEmailValid(email)) {
      setEmailError(errorMessages.emailField);
      hasError = true;
    }else{
      setEmailError(null)
    }

    if(hasError){
      return;
    }
   
   
    e.preventDefault();
    props.onSubmit({
      id: (item ? item.id : null),
      whatsapp, phone, firstName, roles, password,
      lastName, email, gender: gender.value
    })
  }

  return (<form style={{ padding: '15px' }}>
    <FormInput
      field={"firstName"}
      title={"הזן שם פרטי"}
      value={firstName}
      onChange={setFirstName}
      errorMessage={firstNameError}
    />
    <FormInput
      field={"lastName"}
      title={"הזן שם משפחה"}
      value={lastName}
      onChange={setLastName}
      errorMessage={lastNameError}
    />
    <FormInput
      field={"email"}
      title={"הזן דואר אלקטרוני"}
      value={email}
      onChange={setEmail}
      errorMessage={emailError}
    />
    <FormInput
      field={"phone"}
      title={"הזן מספר טלפון"}
      value={phone}
      onChange={setPhone}
    />
    <FormInput
      field={"whatsapp"}
      title={"הזן מספר וואטצאפ"}
      value={whatsapp}
      onChange={setWhatsapp}
    />
    <FormInput
      field={"password"}
      title={"הזן סיסמא"}
      type="password"
      value={password}
      onChange={setPassword}
      errorMessage={passwordErrror}
    />
    <SelectInputForm
      title={'בחר מין'}
      value={gender}
      onChange={setGender}
      options={options}
    />
    <div 
    style={{display:'flex',flexDirection:'column',alignItems:'end',rowGap:'1rem'}}>
     <Checkbox 
    title={'הרשאת משתמש'}
    field={'user'}
    onChange={updateRoles}
    />
    <Checkbox 
    title={'הרשאת אדמין'}
    field={'admin'}
    onChange={updateRoles}
    />
    
    </div>
    <button className="btn btn-primary" onClick={onSubmit} >עדכן</button>
  </form>
  )
}

export default UserForm;