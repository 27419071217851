import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { axiosInstance} from "../../axiosInstance";
import {  toast } from 'react-toastify';

const URL = "/api/v1/lessonsparticipants"
export const getParticipantsByLessonId = createAsyncThunk(
    'lessons/getParticipantsByLessonId',
    async (lessonId) => {
      try {
       
        const response = await axiosInstance.getInstance().get(URL + "/" + lessonId)
     
        return response.data
      } catch (err) {
        console.log(err)
      }
    }
  )
  export const addParticipant= createAsyncThunk(
    'add/participant',
    async (lessonParticipant) => {
      try {
       
        const response = await axiosInstance.getInstance().post(URL,lessonParticipant)
        toast("הוספת משתמש לשיעור בוצעה בהצלחה ",{ position: toast.POSITION.TOP_CENTER })
    
        return response.data
      } catch (err) {
        console.log(err)
      }
    }
  )
  export const removeParticipant= createAsyncThunk(
    'remove/participant',
    async (lessonId) => {
      try {
       
        const response = await axiosInstance.getInstance().get(URL + "/" + lessonId)
        toast("מחיקת משתמש מהשיעור בוצעה בהצלחה ",{ position: toast.POSITION.TOP_CENTER })
    
        return response.data
      } catch (err) {
        console.log(err)
      }
    }
  )
 

  export const lessonsParticipantsSlice = createSlice({
    name: "lessonParticipants",
    initialState: {
        data: [],
      loading:false
    },
    reducers: {
    fetchLessonPartcipants(state, action) {
        state.data = action.payload
      }
    },
    extraReducers: (builder) => {
        builder.addCase(getParticipantsByLessonId.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.data = payload;
         
        })
        builder.addCase(getParticipantsByLessonId.pending, (state, { payload }) => {
            state.loading = true;
        })
        builder.addCase(getParticipantsByLessonId.rejected, (state, { payload }) => {
            state.loading = false;
            state.data = [];
        })
        builder.addCase(addParticipant.fulfilled, (state, { payload }) => {
          state.loading = false;
          state.data = [...state.data,payload];
       
      })
      builder.addCase(addParticipant.pending, (state, { payload }) => {
          state.loading = true;
      })
      builder.addCase(addParticipant.rejected, (state, { payload }) => {
          state.loading = false;
     
      })
       
     
     
        // add more case
      }
  })
  export const { fetchLessonPartcipants } = lessonsParticipantsSlice.actions