import { Languages } from "../components/constants/languages.consts";

export const languageType = Object.freeze({
    hebrew:'he',
    english:'en',
    getElementByIndex:function(index){
        const keys = Object.keys(this);
        return this[keys[index - 1]]
    }
})

export const getlanguageName =(id) =>{
   
    const values = Object.values(Languages);
    const value = values.find(v => v.value === id)
    return value ? value.label : 'unknown';

}