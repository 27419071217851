import React,{useEffect} from 'react';
import { useDispatch } from 'react-redux';
import './App.scss';
import Layout from './layout'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { pdfjs } from 'react-pdf';

import { getTopics } from './redux/reducers/topics-reducer';
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

function App() {
  
  

  const dispatch = useDispatch();

  const handleScroll = (e)=> {
  // console.log(`${e.target.scrollHeight},${e.target.scrollTop} =${e.target.clientHeight}`)
    // let scrollTop = event.srcElement.body.scrollTop,
         //itemTranslate = Math.min(0, scrollTop/3 - 60);
        //  console.log('scroll')
        //  const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
        //  if (bottom) { 
        //      console.log('bottom')
        //   }
       
 }

 useEffect(()=>{
  dispatch(getTopics())
 },[])

  console.log(process.env.REACT_APP_NOT_SECRET_CODE);
  return (
    <div className="App" onWheel={handleScroll}>
        <ToastContainer
position="top-right"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="light"
/>
{/* Same as */}
<ToastContainer />
      <Layout />
    </div>
  );
}

export default App;
