import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosInstance}  from "../../axiosInstance";

const BASE_URL = "/api/v1/forms";
export const getFormsList = createAsyncThunk(
    'projects/allfroms',
    async (lessonId) => {
      try {
       
        const response = await axiosInstance.getInstance().get(BASE_URL+"/getByLessonId/" + lessonId)
     
        return response.data
      } catch (err) {
      //  console.log(JSON.stringify(AppAxiosInstance.getInstance()))
        console.log(err)
      }
    }
  )
  export const getFormById = createAsyncThunk(
    'projects/formById',
    async () => {
      try {
       
        const response = await axiosInstance.getInstance().get(BASE_URL)
     
        return response.data
      } catch (err) {
      //  console.log(JSON.stringify(AppAxiosInstance.getInstance()))
        console.log(err)
      }
    }
  )
  

  export const formsSlice = createSlice({
    name: "forms",
    initialState: {
        data: [],
      loading:false
    },
    reducers: {
    fetchForms(state, action) {
        state.data = action.payload
      }
    },
    extraReducers: (builder) => {
        builder.addCase(getFormsList.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.data = payload;
          
        })
        builder.addCase(getFormsList.pending, (state, { payload }) => {
            state.loading = true;
        })
        builder.addCase(getFormsList.rejected, (state, { payload }) => {
            state.loading = false;
        })
        builder.addCase(getFormById.fulfilled, (state, { payload }) => {
          state.loading = false;
          state.data = payload;
        
      })
      builder.addCase(getFormById.pending, (state, { payload }) => {
          state.loading = true;
      })
      builder.addCase(getFormById.rejected, (state, { payload }) => {
          state.loading = false;
      })
      
      }
  })
  export const { fetchForms: fetchForms } = formsSlice.actions