import React,{useRef,useEffect} from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

export function FaqEditor({ value, onChange }) {
 
    const ref = useRef()

    useEffect(()=>{
        console.log(ref.current.editor.getText().replace('\n',''))
    },[value])
    return <>
    
        <ReactQuill style={{ background:'#fff', height: '40rem' }} theme="snow" ref={ref} value={value} onChange={(e)=>{  onChange(e)}} />
        </>;
}