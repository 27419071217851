export const topicAndLevelsOptions = [
    { label: "כל הנושאים", value: 1 },
    { label: "הלכה - מתחילים", value: 2 },
    { label: "הלכה - בנונים", value: 3 },
    { label: "הלכה - מתקדמים", value: 4 },
    { label: "חסידות - מתחילים", value: 5 },
    { label: "חסידות - בנונים", value: 6},
    { label: "חסידות - מתקדמים", value: 7 },
    { label: "גמרא - מתחילים", value: 8 },
    { label: "גמרא - בנונים", value: 9 },
    { label: "גמרא - מתקדמים", value: 10 },
    { label: "פרשת שבוע - מתחילים", value: 11 },
    { label: "פרשת שבוע - בנונים", value: 12 },
    { label: "פרשת שבוע - מתקדמים", value: 13 },
]