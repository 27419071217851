import React, { useState, useEffect } from 'react';

import AutocompleteList from './autocomplete-list';
import ArrayUtil from '../../../utils/array-util';

let index = 1;
const Autocomplete = ({ list, onClick,initialValue }) => {
  const [val, setVal] = useState('');
  const [arr, setArr] = useState([]);
  const [focusIndex, setFocusIndex] = useState(-1);

  const KEYDOWN = 40, KEYUP = 38, ENTER = 13;

  const onKeyDown = (e) => {
    if (e.keyCode === ENTER) {
      setVal(arr[focusIndex].name)
    }
    if (e.keyCode === KEYDOWN && index >= 1 && index <= arr.length) {
      index++;
      console.log(index)
      setFocusIndex(index % arr.length);

    }
    if (e.keyCode === KEYUP && index >= 1) {
      index--;
      setFocusIndex(index % arr.length);

    }


  }

  const handleOnClick = (item) => {

    onClick(item)
    setVal(item.name)
    setArr([]);
  }

  const canSetArr = (_arr) => {
    return _arr.length > 1 ||
      (_arr.length === 1 && _arr[0].name !== val)
  }
  useEffect(() => {
    const _arr = [];
    if (val && val !== '') {

      list.forEach((item) => {
        const subStr = item.name.substring(0, val.length)
        if (val.toLowerCase() === subStr.toLowerCase()) {
          _arr.push(item);
        }
      })

    }
    if (canSetArr(_arr)) {
      setArr(_arr);
    }

  }, [val])

    useEffect(() => {
      index = 1;
    }, [])

    useEffect(()=>{
      if(list && initialValue && initialValue >= 0){
          const product = ArrayUtil.getItemById(list,initialValue);
          setVal(product.name)
      }
    },[initialValue])

  return (<div className="autocomplete" style={{ width: '100%' }}>

    <input id="myInput" autoComplete='off' type="text" name="myCountry" value={val} onKeyUp={onKeyDown} onChange={({ target }) => setVal(target.value)} />
    <AutocompleteList list={arr} focusIndex={focusIndex} typed={val} onClick={(item) => { handleOnClick(item) }} />
  </div>)
}
export default Autocomplete;