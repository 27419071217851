import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Modal from '../../components/modals/modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'

import LessonRow from '../../components/tables/LessonRow';
import THead from '../../components/tables/thead'
import LessonParticipantsTable from './lessonParticipantsTable';
import LessonForm from '../../components/forms/lesson-form';
import { getLessons, addLesson, updateLesson } from '../../redux/reducers/lessons-reducer';
import SelectInputForm from '../../components/forms/select-input-form';
import { LessonsFilterByLanguage } from './lessons-filters/lessonsFilterByLanguage';
import { LessonsFilterByDates } from './lessons-filters/lessonsFilterByDates';

const Lessons = () => {

    const dispatch = useDispatch()

    const lessons = useSelector(state => state.lessons);
    const [lessonItem, setLessonItem] = useState(null);
    const [participants, setParticipants] = useState([]);
    const [shouldShowModal, setShouldShowModal] = useState(false)
    const [shouldShowFormModal, setShouldShowFormModal] = useState(false);
    const [filter,setFilter] = useState(0);
    const options = [{value: 0, label:'ללא פילטר' },{ value: 1, label: 'חיפוש לפי שפה' }, { value: 2, label: 'חיפוש לפי תאריך' }]
    const LanguageOptions = [{ value: 1, label: 'עברית' }, { value: 2, label: 'English' }]


    const onParticipantsClicked = (index) => {
        setParticipants(lessons.data[index].lessonParticipants);
        setShouldShowModal(true);
    }
    const getLessonsRows = () => {
        if (lessons && lessons.data) {
            return lessons.data.map((item, index) => <LessonRow key={index} index={index} onEdit={onEdit} onParticipantsClicked={onParticipantsClicked} item={item} />)
        }
    }
    const onAdd = () => {
        setLessonItem({});
        setShouldShowFormModal(true)
    }
    const onEdit = (index) => {
        setLessonItem(lessons.data[index]);
    }
    const onSubmitLesson = (lesson) => {
        if (lesson.id) {
            dispatch(updateLesson(lesson))
        } else {
            dispatch(addLesson(lesson))
        }

    }

    useEffect(() => {
       
    }, [lessons.data])

    useEffect(() => {
        dispatch(getLessons())
    }, [])

    useEffect(() => {
        if (lessonItem) {
            setShouldShowFormModal(true)
        } else {
            setShouldShowFormModal(false)
        }
    }, [lessonItem])

    return (<div>
        <Modal shouldOpen={shouldShowModal} onClose={() => { setShouldShowModal(false) }}>
            <LessonParticipantsTable items={participants} />
        </Modal>
        <Modal shouldOpen={shouldShowFormModal} onClose={() => { setLessonItem(null); }}>
            <LessonForm item={lessonItem} onSubmit={onSubmitLesson} />
        </Modal>
        <div className="well" style={{ background: '#dde8ef', display: 'flex',gap:"1rem", flexDirection: 'row',margin:'1rem 3rem' }}>
            <div>
                <SelectInputForm
                    options={options}
                    onChange={({value})=>{setFilter(value)}}
                />
            </div>

           {filter === 1 && <LessonsFilterByLanguage options={LanguageOptions} /> }
           {filter === 2 && <LessonsFilterByDates /> }
        </div>
        <table className="table table-stripped" style={{ width: '75%', margin: '1.5rem auto', fontSize: '1.6rem', background: '#fff', boxShadow: '1px 1px #888888' }}>
            <THead
                headerColor={'#fff'}
                headers={["מזהה", "שפה", "זמן", "שם הרב", "כותרת", "כותרת משנה", "משתתפים", ""]}
            />
            <tbody>
                {getLessonsRows()}
            </tbody>
        </table>
        <button className="button-circle" onClick={onAdd}>
            <FontAwesomeIcon icon={faPlus} />
        </button>
    </div>);
}

export default Lessons