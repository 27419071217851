import React,{useState,useEffect} from 'react';
import { useDispatch} from 'react-redux';

import Autocomplete from './autocomplete/autocomplete';
import InputForm from './input-form';
import CheckboxForm from './checkbox-form';
import ButtonForm from './button-form';



export const UserGroupForm = ({groups,item,onSubmit}) =>{
    const [group,setGroup]                     = useState(null);
    const [initialValue,setInitialValue]       = useState(null);
    const [isActive,setIsActive]               = useState(true);
    const dispatch                             = useDispatch();
    

    useEffect(()=>{
      if(item){
         setInitialValue(item.groupId)
         setGroup(item)
      }
    },[item])

   
    
 
   
   
    return(<form className="advertisement__item__tbl">
       <div className="advertisement__item__tbl-autocomplete">
       <label>בחר קבוצה</label>
        <div className="form-group" style={{ border: '1px solid #ccc', borderRadius: '4px',outline:'none'}}>
       
          <Autocomplete list={groups} onClick={setGroup} initialValue={initialValue} />
        </div>
        </div>
  
      
         <CheckboxForm
            className="advertisement__item__tbl-online"
            name={'onlineOrder'}
            title={'פעיל'}
            value={isActive}
            onChange={setIsActive}
         />
         <ButtonForm
            className="advertisement__item__tbl-btn"
            text="שלח"
            style={{width:'100%'}}
            onSubmit={(e)=>{onSubmit(e,group.id,isActive)}}
          />
    </form>)
}

