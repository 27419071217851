import React from "react";

const LessonParticipantRowExtended  = ({item}) =>{
    return(<tr>
        <td style={{width:'10%'}}>{item.id}</td>
        <td style={{width:'25%'}}>{item.firstName}</td>
        <td style={{width:'25%'}}>{item.lastName}</td>
        <td style={{width:'25%'}}>{item.email}</td>
        <td>{item.phone}</td>
        <td>{item.whatsapp}</td>
        <td></td>
    </tr>)
}

export default LessonParticipantRowExtended;