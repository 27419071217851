import React from "react";
import { AboutYou } from "./aboutYou";
import { AskTheRabbi } from "./askTheReabbi";
import { ContactingUs } from "./contactingUs";
import { Cookies } from "./cookies";
import { Links } from "./Links";
import { LocationData } from "./locationData";
import { PrivacyPolicyContent } from "./privacyPolicyContent";
import { Security } from "./security";
import { TermsOfService } from "./termsOfService";

export const PrivacyPolicyContainer = ({ sectionName }) => {

    const getSection = () => {
        switch (sectionName) {
            case 'aboutYou':
                return <AboutYou />;

            case 'askTheRabbi':
                return <AskTheRabbi />;

            case 'contactingUs':
                return <ContactingUs />;

            case 'cookies':
                return <Cookies/>;
            case 'links':
                return <Links />;

            case 'locationData':
                return <LocationData />;

            case 'privacyPolicyContent':
                return <PrivacyPolicyContent />;

            case 'security':
                return <Security />;
            
            case 'termsOfService':
                return <TermsOfService />;
                default:
                    return <PrivacyPolicyContent />;
        }
    }
    return (<>
        {getSection()}
    </>);
}