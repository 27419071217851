import React,{useEffect,useState} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit,faTrash } from '@fortawesome/free-solid-svg-icons';
import ArrayUtil from '../../utils/array-util';
import { Link } from 'react-router-dom';

const UserRow = (props) =>{

  
    const {item} = props;
    
    return(   <tr style={{letterSpacing:'0.4px'}}><th scope="row" style={{height:'5rem'}}>{props.item.id}</th>
    <td>{item.firstName}</td>
    <td>{item.lastName}</td>
    <td>{item.email}</td>
    <td>{item.phone}</td>
    <td>{item.whatsapp}</td>
    <td>{item.gender}</td>
    <td>{item.isActive ? "פעיל":"לא פעיל"}</td>
    <td><Link to={'/usersgroups/'+ props.item.id} >קבוצות</Link></td>
    
    <td>
        <span style={{padding:'10px'}} onClick={()=>{props.onEdit(props.index);}}>
            <FontAwesomeIcon icon={faEdit} />
        </span>
      
    </td>
    </tr>
    )
}

export default UserRow;