import React from "react";
import {useDispatch,useSelector} from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { Pagination } from '../../components/tables/pagination';
import LessonReferenceRow from '../../components/tables/LessonReferenceRow';
import SelectInputForm from "../../components/forms/select-input-form";

export function LessonsReferencesList({data,onViewFileClicked,onEditClicked,onRemoveClicked,onPageChange,setItem,currentPage,setShouldShowModal,lessonSubSubjects,onLessonCategoryChanged,totalPages}){

    
    const getLessonsReferencesRows = () =>{
        if(data){
            
            return data.map((item,index)=><LessonReferenceRow key={index} index={index} item={item} onEdit={onEditClicked} onViewFileClicked={onViewFileClicked} onRemove={onRemoveClicked}  />)
        }
    }

    return(<>
        <div style={{ display: 'flex' }}>

            <div style={{ flex: 1 }}>
            
                    <SelectInputForm
                      
                        options={lessonSubSubjects}
                        onChange={onLessonCategoryChanged}
                    />
               
            </div>
            <div style={{ flex: 1 }}></div>
            <div style={{ flex: 1 }}></div>
            <div style={{ flex: 1 }}></div>

        </div>
        <Pagination pages={totalPages} onPageClicked={onPageChange} currentPage={currentPage} />
        <table className="table table-stripped" style={{ width: '85%', margin: '10vh auto',  background: '#fff', boxShadow: '1px 1px #888888' }}>
            <thead>
                <tr>
                    <th>מספר מזהה</th>
                    <th>כותרת</th>
                    <th>כותרת משנה</th>
                    <th>שם הקובץ</th>
                    <th>תוכן</th>
                    <th>קטגוריה</th>
                    <th>תת קטגוריה</th>
                    <th>שפה</th>
                    <th>הצג קובץ</th>
                    <th></th>
                    <th></th>
                    
                </tr>
            </thead>
            <tbody>
              {getLessonsReferencesRows()}
            </tbody>
        </table>
        <button className='button-circle' onClick={()=>{ setItem(null); setShouldShowModal(true)}}>
        <FontAwesomeIcon icon={faPlus} />
        </button>
        </>)
}