import React,{useState} from 'react';
import {useDispatch} from 'react-redux';

import InputForm from './input-form';
import ButtonForm from './button-form';
import ValidatorUtil from '../../utils/validator-util';
import { doSignUp } from '../../redux/reducers/auth-reducer';


const SignupForm = () =>{

   const dispatch                                  = useDispatch();
   const [firstName,setFirstName]                  = useState();
   const [lastName,setLastName]                    = useState();
   const [email,setEmail]                          = useState();
   const [password,setPassword]                    = useState();
   const [confirmPassword,setConfirmPassword]      = useState();

   const onSubmit = (e) =>{
      e.preventDefault();
      if(isFormValid()){
         dispatch(doSignUp({first_name:firstName,last_name:lastName,email,password}))        
      }
   }

   const isFormValid = ()=>{
      if(ValidatorUtil.isEmptyField(firstName)){
         return false;
      }
     
      if(ValidatorUtil.isEmptyField(lastName)){
         return false;
      }

      if(!ValidatorUtil.isEmailValid(email)){
         return false;
      }

      if(password !== confirmPassword){
         return false;
      }

      if(!ValidatorUtil.isPasswordValid(password)){
         return false;
      }

      return true;
   }
    return(<form style={{width:'25%',margin:'0 auto'}}>
        <InputForm 
           field={"fname"}
           title={"Enter first name"}
           onChange={setFirstName}
        />
        <InputForm 
           field={"lname"}
           title={"Enter last name"}
           onChange={setLastName}
        />
        <InputForm 
           field={"email"}
           type={'email '}
           title={"Enter your email"}
           onChange={setEmail}
        />
        <InputForm 
           field={"password"}
           type={'password'}
           title={"Enter password"}
           onChange={setPassword}
        />
        <InputForm 
           field={"confirmpassword"}
           type={'password'}
           title={"Confirm your password"}
           onChange={setConfirmPassword}
        />
        <ButtonForm
         style={{width:'100%'}}
         text="send"
         onSubmit={onSubmit}
         />
    </form>)

}

export default SignupForm;