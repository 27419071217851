import React,{useEffect,useState} from "react";
import { useSelector,useDispatch } from "react-redux";
import InputForm from "../../components/forms/input-form";
import { FaqEditor } from "./faq-editor";
import ButtonForm from "../../components/forms/button-form";
import { getFaqById,updateFaq,addFaq } from "../../redux/reducers/faq-reducer";

export const FaqEdit = ({match}) => {

    const faqId = match.params.faqId;
    const topics = useSelector(state=>state.topics);
    const {currentFaq} = useSelector(state=>state.faqs);
    const [answer,setAnswer] = useState('');
    const [question,setQuestion] = useState('');
    const dispatch = useDispatch();


    useEffect(()=>{
        if(faqId){
            dispatch(getFaqById(faqId))
        }
      
    },[])

    useEffect(()=>{
        console.log(topics)
    },[topics])

    useEffect(()=>{
        setAnswer(currentFaq?.answer);
        setQuestion(currentFaq?.question);
       console.log(currentFaq)
    },[currentFaq])

    const saveFaq = () =>{
        const obj= {question,answer}
        if(faqId){
            obj.id = faqId
            dispatch(updateFaq(obj))
        }else{
            dispatch(addFaq(obj))
        }
        console.log(answer,question)
    }
    return (<div style={{padding:"10vh 10vw 0 10vw",display:'flex',flexDirection:'column',gap:'1rem'}}>
        <InputForm  onChange={setQuestion} value={question}/>
        <FaqEditor value={answer} onChange={setAnswer} />
        <br />
        <br />
        <ButtonForm text={'שמור'} onSubmit={saveFaq} />
    </div>)
}