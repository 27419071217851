import React,{useState} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

import ProfileSidebar from '../components/sidebar/profile-sidebar';
import MenuSidebar from '../components/sidebar/menu-sidebar'

const Sidebar = ({onRouteChanged}) =>{
    const [showMenu,setShowMenu] = useState(null);

    return(<>
    <div style={{position:'absolute',left:'25px',top:'10px'}} onClick={()=>{setShowMenu(true)}}>
        <img style={{height:'3.5rem',filter: 'invert(1)'}} src={require('./images/icons8-menu-100.png')} />
    </div>
    <div className="sidebar" style={{animationName: (showMenu === true ? 'moveIn' : showMenu === false ? 'moveOut' : ''),animationDuration:'0.5s',animationFillMode:'forwards'}}>
    <FontAwesomeIcon style={{position: 'absolute', top: '0',left: '18rem'}} onClick={()=>{setShowMenu(false)}} icon={faTimes} />
       
        {/* <ProfileSidebar onClose={()=>{setShowMenu(false)}} /> */}
        <MenuSidebar onRouteChanged={onRouteChanged} onCloseClicked={(()=>{ setShowMenu(false)})} />
    </div></>);
}

export default Sidebar;