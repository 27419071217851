import React,{useState} from 'react';

const FileInputForm = ({className,title,onChange,errorMessage}) =>{

  
  const [isSelected,setIsSelected] = useState(false)
  const [fileName,setFileName] = useState('');
  const changeHandler = (event) => {
    console.log(event.target.files[0])
    if(event.target.files){
    setFileName(event.target.files[0].name)
		onChange(event.target.files[0]);
		setIsSelected(true);
    }else{
      setFileName('')
      onChange(null);
      setIsSelected(false);
    }
	};

function openDialog(e) {
  e.preventDefault();
  document.getElementById('fileid').click();
}
    return( <div className={'form-group' + (className ? ' ' + className : '')}>
    <label htmlFor='file'>{fileName}</label>
    
    <div>
			<input type="file" name="file" style={{display:'none'}} onChange={changeHandler} id="fileid" hidden={true}/>
			<div>
				<button className='btn btn-primary' onClick={openDialog}>{title}</button>
			</div>
		</div>
    {errorMessage && <p style={{color:'red'}}>{errorMessage}</p>}
  </div>)
}

export default FileInputForm;