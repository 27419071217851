import React from 'react';
import ReactDOM from 'react-dom';

const Modal =({shouldOpen,width,onClose,title,children})=>{

    if(shouldOpen){
      return ReactDOM.createPortal(<div  className="modal" >
     <div className="modal-dialog">
      <div className="modal-content" style={{minWidth:'40rem'}}>
      <div className="modal-header">
      <button type="button" className="close" data-dismiss="modal" onClick={onClose} aria-label="Close"><span aria-hidden="true">×</span></button>
        <h5 className="modal-title">{title}</h5>
      </div>
      <div className="modal-body" style={{width}}>
       {children}
       </div>
      </div>
      </div>
    
    </div>,document.body)
    }else{
      return(<React.Fragment></React.Fragment>)
    }
   
}

export default Modal;