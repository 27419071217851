import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { axiosInstance} from "../../axiosInstance";
import ArrayUtil from "../../utils/array-util";
import {  toast } from 'react-toastify';
import { anauthorizedErrorHandler } from "../../handlers/error.handler";

const URL = "/api/v1/lessons"
export const getLessons = createAsyncThunk(
    'lessons/get',
    async () => {
      try {
       
        const response = await axiosInstance.getInstance().get(URL)
     
        return response.data
      } catch (err) {
        anauthorizedErrorHandler(err);
       
      }
    }
  )
  export const getLessonsByWeekId = createAsyncThunk(
    'lessons/getLessonsByWeekId',
    async (weekId) => {
      try {
       
        const response = await axiosInstance.getInstance().get(`${URL}/getByWeekId/${weekId}`)
     
        return response.data
      } catch (err) {
        anauthorizedErrorHandler(err);
      }
    }
  )
  export const addLesson = createAsyncThunk(
    'lessons/add',
    async (lessons) => {
      try {
       
        const response = await axiosInstance.getInstance().post(URL,lessons)
        toast("הוספת שיעור בוצעה בהצלחה",{ position: toast.POSITION.TOP_CENTER })
        return response.data
      } catch (err) {
        anauthorizedErrorHandler(err);
      }
    }
  )
  export const updateLesson = createAsyncThunk(
    'lessons/update',
    async (lesson) => {
      try {
       
        const response = await axiosInstance.getInstance().put(URL + "/" + lesson.id,lesson)
        toast("עידכון שיעור בוצע בהצלחה",{ position: toast.POSITION.TOP_CENTER })
        return response.data
      } catch (err) {
        anauthorizedErrorHandler(err);
      }
    }
  )

  export const lessonsSlice = createSlice({
    name: "lessonReferences",
    initialState: {
        data: [],
      loading:false
    },
    reducers: {
    fetchLessonReferences(state, action) {
        state.data = action.payload
      }
    },
    extraReducers: (builder) => {
        builder.addCase(getLessons.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.data = payload;
         
        })
        builder.addCase(getLessons.pending, (state, { payload }) => {
            state.loading = true;
        })
        builder.addCase(getLessons.rejected, (state, { payload }) => {
            state.loading = false;
            state.data = [];
        })
        builder.addCase(getLessonsByWeekId.fulfilled, (state, { payload }) => {
          state.loading = false;
          state.data = payload;
       
      })
      builder.addCase(getLessonsByWeekId.pending, (state, { payload }) => {
          state.loading = true;
      })
      builder.addCase(getLessonsByWeekId.rejected, (state, { payload }) => {
          state.loading = false;
          state.data = [];
      })
        builder.addCase(addLesson.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.data = [...state.data,payload];
        
        })
        builder.addCase(addLesson.pending, (state, { payload }) => {
            state.loading = true;
        })
        builder.addCase(addLesson.rejected, (state, { payload }) => {
            state.loading = false;
            state.data = [];
        })
        builder.addCase(updateLesson.fulfilled, (state, { payload }) => {
          state.loading = false;
          state.data = ArrayUtil.replaceItemById(state.data,payload.id,payload);
      
      })
      builder.addCase(updateLesson.pending, (state, { payload }) => {
          state.loading = true;
      })
      builder.addCase(updateLesson.rejected, (state, { payload }) => {
          state.loading = false;
          state.data = [];
      })
        // add more case
      }
  })
  export const { fetchLessonReferences } = lessonsSlice.actions