import React, { useState,useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {useDispatch,useSelector} from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'

import Modal from '../../components/modals/modal';
import { getUsers,addUser } from '../../redux/reducers/users-reducer';
import UserForm from "../../components/forms/user-form";
import THead from '../../components/tables/thead';
import UserRow from '../../components/tables/UserRow';
import { UsersFilters } from './usersFilters';

const UsersPage = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const users = useSelector(state=>state.users);
  const publisherDepartments = useSelector(state=>state.publisherDepartments);
  
  const [shouldOpenModal, setShouldOpenModal] = useState(false);
  const [userItem,setUserItem] = useState({});
  useEffect(()=>{
    dispatch(getUsers())
    
  },[]);

 

  const onAdd = () =>{
    setUserItem(null);
    setShouldOpenModal(true);
  }
  const onEdit = (index) =>{
    setUserItem(users.data[index]);
    setShouldOpenModal(true);
  }

 

  const onSubmit = (item) =>{
    
      if(item.id){
        //  dispatch(usersAction(UPDATE_USER,item));
      }else{
        dispatch(addUser(item));
      }
  }
  
  const getUsersRows = () =>{
     if(users && Array.isArray(users.data) && users.data.length > 0){
     
       return users.data.map((item,index)=>{
         return <UserRow 
                 key={index}
                 item={item} index={index}
                 onEdit={onEdit}
               
                  />;})
     }
  }
  useEffect(()=>{
    if(users.data === 'error'){
      navigate('/');
    }
  },[users])

  const onWheel = (e) =>{
    console.log(`${e.currentTarget.body.scrollHeight},${window.pageYOffset + window.visualViewport.height }`)
    const bottom = e.currentTarget.body.scrollHeight - (window.pageYOffset + window.visualViewport.height)=== 0;
    if (bottom) { 
      dispatch(getUsers())
       console.log('bottom')
     }
  }
  useEffect(()=>{
    document.addEventListener("wheel", onWheel);
  //   document.body.onscroll = function( e ) {
     
  //     console.log(`${e.target.body.scrollHeight},${window.pageYOffset} =${e.target.body.clientHeight}`)
  //     const bottom = e.target.body.scrollHeight - e.target.body.scrollTop === e.target.body.clientHeight;
  //        if (bottom) { 
  //     //       console.log('bottom')
  //         }
  // } ;
//   document.body.onwheel = function( e ) {
//     console.log(`${e.currentTarget.scrollHeight},${window.pageYOffset + window.visualViewport.height }`)
//     const bottom = e.currentTarget.scrollHeight - (window.pageYOffset + window.visualViewport.height)=== 0;
//     if (bottom) { 
//       dispatch(getUsers())
//        console.log('bottom')
//      }
// };
return () => document.removeEventListener("wheel", onWheel); 
  },[])

  const handleScroll = (e)=> {
   // let scrollTop = event.srcElement.body.scrollTop,
        //itemTranslate = Math.min(0, scrollTop/3 - 60);
        console.log('scroll')
        const bottom = e.target.scrollHeight - e.target.offsetTop === e.target.clientHeight;
        if (bottom) { 
            console.log('bottom')
         }
      
}
  

  return (<div onScroll={handleScroll} >
    <Modal shouldOpen={shouldOpenModal} title={'טופס משתמשים'} width={'40rem'} onClose={() => { setShouldOpenModal(false) }}>
      <UserForm departments={publisherDepartments} item={userItem} onSubmit={onSubmit} />
     </Modal>
     <UsersFilters />
    <table className="table table-stripped" style={{ width: '75%', margin: '10vh auto', fontSize: '1.6rem', background: '#fff', boxShadow: '0px 0px 8px #888888' }}>
    <THead 
      headerColor={'rgb(255 255 255)'}
      headers={["מזהה","שם פרטי","שם משפחה","דוא״ל","מספר טלפון","מספר וואצאפ","זכר או נקבה","סטטוס","קבוצות",""]}
    />
     
      <tbody>
        {getUsersRows()}
      </tbody>
     
    </table>
    <button className="button-circle" onClick={onAdd}>
      <FontAwesomeIcon icon={faPlus} />
    </button>
  
  </div>)
}

export default UsersPage;