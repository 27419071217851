import React from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';

const InputDateForm = ({className,title,field,type,value,onChange}) =>{
    return( <div className={'form-group' + (className ? ' ' + className : '')}>
    <label htmlFor={field}>{title}</label>
    <DayPickerInput 
        type={type || 'text'}
        value={value ? new Date(value) : ''} 
        onChange={({target})=>{onChange(target.value)}}
        id={field}
         />
  </div>)
}

export default InputDateForm;