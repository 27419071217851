import React, { useState } from "react";
import { topicAndLevelsOptions } from "../../utils/lessons-util";

export const LessonLecurerRow = ({ item, index, onStudentsClick }) => {

    const [showDetails, setShowDetails] = useState(false);
    return (<><li className="nested-ul" style={{ fontFamily: 'Roboto', fontSize: '14px', minHeight: '5rem',padding:'0.75rem',background:(index % 2 === 0 ? '#fff':'#f1eded') }}>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'stretch', alignItems: 'center', height: '5rem',padding:'0 5px 0 5px' }}>
            <span><span style={{ cursor: 'pointer', fontSize: '10px', marginRight: '1rem', marginLeft: '.5rem' }} onClick={() => setShowDetails(!showDetails)}>{showDetails ? '▼' : '►'}</span>{item.id}</span>
            <span style={{flex:1}}>{item.firstName + ' ' + item.lastName}</span>
            {/* <td>{item.topicsAndLevels}</td> */}
            {/* <td>students:<button onClick={onStudentsClick}>click me</button></td> */}
            <span style={{flex:1}}>{item.rabbiDetails.skilled}</span>
            <span style={{flex:1}}>{item.rabbiDetails.remark}</span>

        </div>
        {showDetails && <div style={{display:'flex',flexDirection:'row',justifyContent:'stretch'}}>



                        <ul style={{ listStyle: 'none' ,flex:1}}>
                            
                            <li> שפות</li>
                            {item.rabbiDetails.languages.map(l => <li style={{height:'3.5rem'}}>{l}</li>)}
                        </ul>
                    
                        <ul style={{ listStyle: 'none' ,flex:1}}>
                            <li>תלמידים</li>
                            {item.rabbiStudents.map(s => <li style={{height:'3.5rem'}}>{s.user.firstName + ' ' + s.user.lastName}</li>)}
                            </ul>
                    
                        <ul style={{ listStyle: 'none',flex:1 }}>
                        <li>נושאים ורמות</li>
                            {item.rabbiDetails.topicsAndLevels.map(t => <li style={{height:'3.5rem'}}>{topicAndLevelsOptions.find(o => o.value === t) ? topicAndLevelsOptions.find(o => o.value === t).label : ''}</li>)}
                            </ul>
                   </div>

        }</li></>)
}