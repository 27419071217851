import {axiosInstance} from '../../axiosInstance';

export const SEND_NOTIFICATION = 'send_notification';
export const GET_NOTIFICATIONS = 'get_notifications';
const BASE_URL = 'api/notifications'

const notificationsAction = (type,obj) =>{

    const axios = axiosInstance.getInstance();
    switch(type){
            case SEND_NOTIFICATION:
                return dispatch =>{
                    axios.post(BASE_URL,obj).then(({data})=>{
                        dispatch({type:SEND_NOTIFICATION,payload:data});
                    })
                   
                }
            case GET_NOTIFICATIONS:
                return dispatch =>{
                    axios.get(BASE_URL).then(({data})=>{
                        dispatch({type:GET_NOTIFICATIONS,payload:data});
                    })
                   
                }


    }
}

export default notificationsAction;