import React from "react";

export const AskTheRabbi = () =>{

    return(<div style={{ animation: "fadeInAnimation ease 2s",
    animationIterationCount: 1,
    animationFillMode: "forwards"}}>
           <h2>
        Feedback & Ask the Rabbi
        </h2>
        <p>
        All communication held via our Feedback is strictly confidential and is not shared with any parties outside the organization. For your protection, a secure, encrypted link is used to load your most recent response and is not publicly accessible. Your email address is never revealed to the public except through this secure link.
        </p>
    </div>)
}