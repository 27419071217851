import React from 'react';
import ReactDOM from 'react-dom';
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import SignupForm from '../forms/signup-form';

const SignupModal =(props)=>{

    if(props.shouldOpen){
      return ReactDOM.createPortal(<div  className="signup__modal" >
          <FontAwesomeIcon className="signup__modal-close" onClick={props.onClose} icon={faTimes} />
          <h1 style={{fontFamily: 'Lato',letterSpacing: "5px",textTransform: "uppercase",marginBottom: "4rem"}}>Register</h1>
    <SignupForm />
    </div>,document.body)
    }else{
      return(<React.Fragment></React.Fragment>)
    }
   
}

export default SignupModal;