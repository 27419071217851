import { configureStore } from '@reduxjs/toolkit';

import { usersSlice } from './reducers/users-reducer';
import { lessonsReferencesSlice } from './reducers/lessonReferences-reducer';
import { lessonsSubCategoriesSlice } from './reducers/lessonsSubCategories-reducer';
import { lessonsCategoriesSlice } from './reducers/lessonsCategories.reducer';
import { lessonsParticipantsSlice } from './reducers/lessonsParticipants-reducer';
import { lessonsSlice } from './reducers/lessons-reducer';
import { contactsSlice } from './reducers/contacts-reducer';
import { authSlice } from './reducers/auth-reducer';
import { groupsSlice } from './reducers/groups-reducer';
import { usersGroupsSlice } from './reducers/usersGroups-reducer';
import { notificationsSlice } from './reducers/notifications-reducer';
import { faqsSlice } from './reducers/faq-reducer';
import { topicsSlice } from './reducers/topics-reducer';
import { lessonsScheduleSlice } from './reducers/weeklyLessons-reducer';
import { activitiesSlice } from './reducers/activities.reducer';
import { lessonsLecturersSlice } from './reducers/lessons.lecturer.reducer';
import { formsSlice } from './reducers/dynaimicforms.reducer';


export const store = configureStore({
    reducer: {
      users:usersSlice.reducer,
      lessonsReferences:lessonsReferencesSlice.reducer,
      lessonsSubCategories:lessonsSubCategoriesSlice.reducer,
      lessonsCategories:lessonsCategoriesSlice.reducer,
      lessons:lessonsSlice.reducer,
      contacts:contactsSlice.reducer,
      auth:authSlice.reducer,
      groups:groupsSlice.reducer,
      usersgroups:usersGroupsSlice.reducer,
      notifications:notificationsSlice.reducer,
      lessonsParticipants:lessonsParticipantsSlice.reducer,
      faqs:faqsSlice.reducer,
      topics:topicsSlice.reducer,
      lessonsSchedule:lessonsScheduleSlice.reducer,
      activities:activitiesSlice.reducer,
      lessonsLecturers:lessonsLecturersSlice.reducer,
      forms:formsSlice.reducer
    },
  })
//export const store = createStore(rootReducer, applyMiddleware(thunk))