import React from 'react';

const AutocompleteItem = ({item,typed,onClick,isFocus}) =>{
    const length = typed && typed.length ? typed.length : 0;
    const {name}= item
    const fPart = name.substring(0,length);
    const sPart = name.substring(length,name.length);
    return(<div className={isFocus ? 'autocomplete-active' :''} onClick={()=>{onClick(item)}}><strong>{fPart}</strong>{sPart}</div>)
}

export default AutocompleteItem;