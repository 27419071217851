import React, { useState,useEffect } from 'react';
import InputForm from './input-form';
import ButtonForm from './button-form';
import SelectInputForm from './select-input-form';

                                                                                                                           


const statusOptions = [{value:0,label:'נפתחה בקשה'},{value:1,label:'בתהליך'},{value:2,label:'טופל'}]


export const MezuzaActivityEditForm = ({item,onSubmit}) => {


    const [numOfCheckedMezuzos, setNumOfCheckedMezuzos] = useState(0)
    const [numOfKosherMezuzos, setNumOfKosherMezuzos] = useState(0)
    const [numOfNonKosherMezuzos, setNumOfNonKosherMezuzos] = useState(0);
    const [numOfBoughtMezuzos, setNumOfBoughtMezuzos] = useState(0);
    const [checkBy, setCheckBy] = useState('');
    const [provider, setProvider] = useState('');
    const [status, setStatus] = useState(0);

   useEffect(()=>{

        if(item){

            setNumOfCheckedMezuzos(item.numOfCheckedMezuzos || 0);
            setNumOfKosherMezuzos(item.numOfKosherMezuzos || 0);
            setNumOfNonKosherMezuzos(item.numOfNonKosherMezuzos || 0);
            setNumOfBoughtMezuzos(item.numOfBoughtMezuzos || 0);
            setCheckBy(item.checkBy || 0);
            setProvider(item.provider || '');
            setStatus(item.status || 0);
        }
   },[item])



    return (<form style={{display:'grid',gridTemplateColumns:'1fr 1fr',gap:'.5rem'}}>
        <InputForm title={'מספר המזוזות שנבדקו'}
            onChange={setNumOfCheckedMezuzos}
            type={'number'}
            value={numOfCheckedMezuzos}
        />
        <InputForm title={'מספר המזוזות הכשרות'}
            onChange={setNumOfKosherMezuzos}
            type={'number'}
            value={numOfKosherMezuzos}
        />
        <InputForm title={'מספר המזוזות הלא כשרות'}
            onChange={setNumOfNonKosherMezuzos}
            type={'number'}
            value={numOfNonKosherMezuzos}
        />
        <InputForm title={'שם הבודק'}
            onChange={setCheckBy}
            value={checkBy}
        />
        <InputForm title={'מספר המזוזות שנקנו'}
            onChange={setNumOfBoughtMezuzos}
            type={'number'}
            value={numOfBoughtMezuzos}
        />
        <InputForm title={'שם המוכר'}
            onChange={setProvider}
            value={provider}
        />
        
        <span style={{gridColumnStart:1,gridColumnEnd:-1}}>
            <SelectInputForm options={statusOptions} value={status} onChange={setStatus} />
        {/* <InputForm title={'סטטוס'}
            onChange={setStatus}
        /> */}
        </span>
        <span style={{gridColumnStart:1,gridColumnEnd:-1,textAlign:'center'}}>
        <ButtonForm text={'עדכן'} onSubmit={(e)=>{e.preventDefault();onSubmit({id:1,provider,status:status.value,numOfBoughtMezuzos,numOfNonKosherMezuzos,numOfKosherMezuzos,numOfCheckedMezuzos})}} />
        </span>

    </form>)
}