import React from "react";

export const Security = () =>{

    return(<div style={{ animation: "fadeInAnimation ease 2s",
    animationIterationCount: 1,
    animationFillMode: "forwards"}}>
     <h2>
        Security
        </h2>
        <p>
        TorahConnect.com uses appropriate procedures to ensure the security of your personal information and to and protect it from unauthorized access. In particular, when we ask you to enter credit card information, we use the top industry standard security protection, including encryption, SSL transmission connection, and a secure server for information storage.
        </p>
    </div>)
}