import ArrayUtil from '../../utils/array-util';

import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { axiosInstance} from "../../axiosInstance";
import { toast } from 'react-toastify';

export const getUsers = createAsyncThunk(
    'users/get',
    async () => {
      try {
        const response = await axiosInstance.getInstance().get("/api/v1/users")
      
        return response.data
      } catch (err) {
        console.log(err)
        return "error"
      }
    }
  )

  export const getUsersByName = createAsyncThunk(
    'users/getUsersByName',
    async (name) => {
      try {
        return axiosInstance.getInstance().get("/api/v1/users/getbyname?query=" + name).then(({data})=>{
          return data;
        })
      
       
      } catch (err) {
        console.log(err)
      }
    }
  )

  export const addUser = createAsyncThunk(
    'users/add',
    async (user) => {
      try {
        const response = await axiosInstance.getInstance().post("/api/v1/users",user)
      
        return response.data
      } catch (err) {
        console.log(err)
      }
    }
  )

 export const getUsersByFilters = createAsyncThunk(
    'users/byFilter',
    async (filters) => {
      try {
        const response = await axiosInstance.getInstance().post("/api/v1/users",filters)
      
        return response.data
      } catch (err) {
        console.log(err)
      }
    }
  )

export const usersSlice = createSlice({
    name: "users",
    initialState: {
      data: [],
     
      loading:false
    },
    reducers: {
    fetchUsers(state, action) {
        state.data = action.payload
      }
    },
    extraReducers: (builder) => {
        builder.addCase(getUsers.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.data = [...state.data,...payload];
         
        })
        builder.addCase(getUsers.pending, (state, { payload }) => {
            state.loading = true;
        })
        builder.addCase(getUsers.rejected, (state, { payload }) => {
            state.loading = false;
        })
        builder.addCase(addUser.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.data = [...state.data,payload];
            toast('משתמש הוסף בהצלחה')
        })
        builder.addCase(addUser.pending, (state, { payload }) => {
            state.loading = true;
        })
        builder.addCase(addUser.rejected, (state, { payload }) => {
            state.loading = false;
        })
        builder.addCase(getUsersByName.fulfilled, (state, { payload }) => {
          state.loading = false;
          state.data = [...payload];
      })
      builder.addCase(getUsersByName.pending, (state, { payload }) => {
          state.loading = true;
      })
      builder.addCase(getUsersByName.rejected, (state, { payload }) => {
          state.loading = false;
      })
        // add more case
      }
  })
  export const { fetchUsers } = usersSlice.actions


const getUpdatedArray = (arr,obj) =>{
    const index = ArrayUtil.getIndexById(arr,{id:obj.id});
   arr[index] = obj;
   return [...arr];
 }

