import React from "react";

export const Links = () =>{

    return(<div style={{ animation: "fadeInAnimation ease 2s",
    animationIterationCount: 1,
    animationFillMode: "forwards"}}>
    <h2>
        Links
        </h2>
        <p>
        We provide links to other Web sites that we believe you will find useful or relevant.  TorahConnect.com is not responsible for the privacy practices or the content of such Web sites.
        </p>
    </div>)
}