import React ,{useEffect,useState} from "react";
import { useSelector,useDispatch } from "react-redux";

import { getFaqs } from "../../redux/reducers/faq-reducer";
import { FaqRow } from "../../components/tables/faqRow";



export const Faqs = () =>{

    const faqs = useSelector(state=>state.faqs);
    const [answer,setAnswer] = useState('')
    const dispatch = useDispatch();

    const onEdit = (index) =>{
        const faq = faqs.data[index];
        setAnswer(faq.answer)
    }
  

    useEffect(()=>{
        dispatch(getFaqs());
    },[])

    const getFaqsRows = ()=>{
        return faqs.data.map((item,index)=><FaqRow key={index} index={index} item={item} onEdit={onEdit} />)
    }
    return(<>
    <table className="table table-stripped" style={{ width: '85%', margin: '10vh auto', fontSize: '1.6rem', background: '#fff', boxShadow: '1px 1px #888888' }}>
            <thead>
                <tr>
                    <th>מספר מזהה</th>
                    <th>שאלה</th>
                    <th>תשובה</th>
                    <th></th>
                    <th></th>
                </tr>
               
            </thead>
            <tbody>{getFaqsRows()}</tbody>
            </table>
           
           

    </>)
}