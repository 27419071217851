import React from 'react';
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from 'react-router-dom'

const MenuSidebarItem = (props) => {
    return (
        <div>
            <Link to={'/' + props.to}>
                <span className="icon">
                    <FontAwesomeIcon icon={props.icon ?? faEnvelope} size="2x" />
                </span>
                <span className="title">&nbsp;{props.title}</span>
            </Link>
        </div>
    )
}

export default MenuSidebarItem;