import Cookies from 'js-cookie'
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { axiosInstance} from "../../axiosInstance";
import {  toast } from 'react-toastify';

const BASE_URL = 'api/v1/auth/';

    export const doLogin = createAsyncThunk(
        'auth/login',
        async (obj) => {
          try {
         
            const response = await axiosInstance.getInstance().post(BASE_URL + 'login',obj)
         
            return response.data
          } catch (err) {
            toast.error('הכניסה למערכת נכשלה',{ position: toast.POSITION.TOP_CENTER })
            console.log(err)
          }
        }
      )

      export const doSignUp = createAsyncThunk(
        'auth/signup',
        async (obj) => {
          try {
           
            const response = await axiosInstance.getInstance().post(BASE_URL + 'signup',obj)
         
            return response.data
          } catch (err) {
            console.log(err)
          }
        }
      )
      export const authSlice = createSlice({
        name: "auth",
        initialState: {
            data: [],
          loading:false
        },
        reducers: {
            fetchAuth(state, action) {
            state.data = action.payload
          }
        },
        extraReducers: (builder) => {
            builder.addCase(doLogin.fulfilled, (state, { payload }) => {
                state.loading = false;
                state.data = payload;
                handleResult(payload);
                toast("נכנסת בהצלחה",{ position: toast.POSITION.TOP_CENTER })
            })
            builder.addCase(doLogin.pending, (state, { payload }) => {
                state.loading = true;
            })
            builder.addCase(doLogin.rejected, (state, { payload }) => {
                state.loading = false;
            })
            
            builder.addCase(doSignUp.fulfilled, (state, { payload }) => {
                state.loading = false;
                state.data = payload;
                handleResult(payload);
                toast("Wow so easy!")
             
            })
            builder.addCase(doSignUp.pending, (state, { payload }) => {
                state.loading = true;
            })
            builder.addCase(doSignUp.rejected, (state, { payload }) => {
                state.loading = false;
            })
          }
      })

      const handleResult = (data) =>{
        Cookies.set('user', data.user);
        localStorage.setItem('secret_token', data.token);
        axiosInstance.setToken(data.token);
    }
      export const { fetchAuth } = authSlice.actions
    
    


