import React,{useEffect,useState} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit,faTrash } from '@fortawesome/free-solid-svg-icons';
import ArrayUtil from '../../utils/array-util';
import { languageType } from '../../utils/language-util';
import { Link } from 'react-router-dom';

const LessonRow = (props) =>{
    const {item,index} = props;
    const [city,setCity] = useState('');


    useEffect(()=>{
        if(props.cities){
            const _city = ArrayUtil.getItemById(props.cities,item.city_id);
            setCity(_city);
        }
       
    },[props.cities])
    return(   <tr style={{background:(index % 2 === 0  ? '#f0f8ff' : '#fff')}}><th scope="row" style={{height:'5rem'}}>{props.item.id}</th>
    <td>{languageType.getElementByIndex( item.languageId)}</td>
    <td>{item.time}</td>
    <td>{item.rabbi.firstName + " " + item.rabbi.lastName }</td>
    <td>{item.title }</td>
    <td>{item.subTitle }</td>
    <td>
    <span style={{padding:'10px'}} onClick={()=>{props.onParticipantsClicked(props.index);}}>
           
            <Link to={`lessonParticipants/${item.id}`}>{item.lessonParticipants.length}</Link>
        </span>
    </td>

    <td>
        <span style={{padding:'10px'}} onClick={()=>{props.onEdit(props.index);}}>
            <FontAwesomeIcon style={{cursor:'pointer'}} icon={faEdit} />
        </span>
        <span style={{padding:'10px'}} onClick={()=>{props.onRemove(props.index);}}>
            <FontAwesomeIcon style={{cursor:'pointer'}}  icon={faTrash} />
        </span>
    </td>
    </tr>
    )
}

export default LessonRow;