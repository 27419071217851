import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash,faEdit } from '@fortawesome/free-solid-svg-icons'
import {Link} from 'react-router-dom';

import DateFormat from '../../utils/date-format-util';

export const UserGroupRow = ({item,index,onEdit}) =>{
    
    return(<tr>
        <td>{item.id}</td>
        <td>{item.group.name}</td>
        <td>{item.isActive ? 'פעיל':'לא פעיל'}</td>
        <td>
        <span style={{padding:'10px',boxShadow:'1px 1px 1px'}} onClick={()=>{ onEdit(index);}}>
            <FontAwesomeIcon style={{cursor:'pointer'}} icon={faEdit} />
        </span>
        
    </td>
    </tr>)
}

