import React from "react";
import SelectInputForm from "../../../components/forms/select-input-form";

export const LessonsFilterByLanguage = ({options,onChange}) =>{

    
    return(<div>
        <SelectInputForm 
        options={options}
        onChange={onChange}
        />
    </div>)
}