import React,{useState,useEffect} from 'react';

import FormInput from "./input-form";
import SelectInputForm from './select-input-form';
import ValidatorUtil from '../../utils/validator-util';
import { errorMessages } from '../../utils/error-messages-util';

const GroupsForm = ({item,onSubmit}) =>{

  
    const [name,setName] = useState('');
    const [nameError,setNameError] = useState('');
    const [description,setDescription] = useState('');
   
    const options = [{value:1,label:'קבוצה פעילה'},{value:2,label:'קבוצה לא פעילה'}]
    const [isActive,setIsActive] = useState(options[0]);
   
    useEffect(() => {

      if (item) {
        setName(item.name);
        setDescription(item.description);
        setIsActive(item.isActive? options[0] : options[1]);
       
      }
  
    }, [item])

   const isFormValid = () =>{
      if(!ValidatorUtil.hasValue(name)){
        setNameError(errorMessages.fieldRequired);
        return false;
      }else{
        setNameError('');
      }
      return true;
   }

    const doSubmit = (e) =>{

      e.preventDefault();
      if(isFormValid()){
        onSubmit({id:(item ? item.id : null),name,description,isActive:isActive.value === 1})
      }
    }
    
    return(<form style={{padding:'15px'}}>
        <FormInput 
            field={"name"}
            title={"הזן שם קבוצה"}
            value={name}
            onChange={setName}
            errorMessage={nameError}
             />
        <FormInput 
            field={"description"}
            title={"הזן תיאור"}
            value={description}
            onChange={setDescription}
             />
             <SelectInputForm 
             title={'בחר סטטוס'}
                value={isActive}
                onChange={setIsActive}
                options={options}
             />
           <button className="btn btn-primary" onClick={doSubmit} >עדכן</button>
  </form>
)
}

export default GroupsForm;