import React from 'react';


const THead = (props) =>{

    const getHeaders = () =>{
        if(props.headers){
            return props.headers.map((header,index)=> <th key={index} scope="col" style={{ color: props.headerColor || '#6090b5',verticalAlign:'middle'}}>{header}</th>)
        }
    }
    return(  <thead style={{background:'linear-gradient(to top, #5d81b6 0%, #154693 100%)'}}><tr style={{height:'5rem',letterSpacing:'1px'}}>
        {getHeaders()}
    </tr>
    </thead>)
}

export default THead;