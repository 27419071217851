import React, { useEffect, useState } from "react";
import InputForm from "../forms/input-form";
import { UsersAutocomplete } from "../forms/autocomplete/usersAutocomplete";
import SelectInputForm from "../forms/select-input-form";
import { Languages } from "../constants/languages.consts";
import { Days } from "../constants/days.const";
import TimePicker from "react-time-picker";
import 'react-time-picker/dist/TimePicker.css';
import 'react-clock/dist/Clock.css';
let languagesOptions = null;
let daysOptions = null;
const scheduledRowColor = '#d4d3e3';
export const WeeklyLessonRow = ({ item, index, onChange, isEdit = false }) => {

    const [title, setTitle] = useState('');
    const [dayInWeek, setDayInWeek] = useState(0);
    const [rabbi, setRabbi] = useState({name:'',value:0});
    const [user, setUser] = useState({name:'',value:0});
    const [id, setId] = useState(null);
    const [time, setTime] = useState(null);
    const [isEditMode, setIsEditMode] = useState(false);
    const [language, setLanguage] = useState('');


    useEffect(() => {
        languagesOptions = Object.keys(Languages).map((key) => { return { value: Languages[key].value, label: Languages[key].label } })
        daysOptions = Object.keys(Days).map((key) => { return { value: Days[key].value, label: Days[key].label } })
    }, [])


    const update = () => {
        onChange({ title, dayInWeek: dayInWeek.value, rabbiId:rabbi.value, userId:user.value, languageId: language.value, time, id })
    }

    useEffect(() => {
        if (item) {
            setTitle(item.title || '');
            setDayInWeek(daysOptions.find(o => o.value === item.dayInWeek) || daysOptions[0]);
            setRabbi(item.rabbi ? {name:item.rabbi.firstName + ' ' + item.rabbi.lastName ,value:item.rabbi.id } : {name:'',value:0});
            setUser(item.user ? {name:item.user.firstName + ' ' + item.user.lastName ,value:item.user.id } : {name:'',value:0})
            setId(item.id);
            setLanguage(languagesOptions.find(o => o.value === item.languageId));
            const formattedTime = item.time.split(":")[0] + ":" + item.time.split(":")[1];
            setTime(formattedTime);
        }
    }, [])
    return (<>{!isEditMode && <tr style={{ background: (item.isScheduled ? scheduledRowColor :index % 2 === 0 ? '#f0f8ff' : '#fff') }}>
        <th scope="row" style={{ height: '5rem' }}>{item.id}</th>
        <td>{item.title}</td>
        <td>{dayInWeek.label}</td>
        <td>{rabbi.name}</td>
        <td>{user.name}</td>
        <td>{time}</td>
        <td>{language.label}</td>
        <td>
            <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                <img onClick={() => { setIsEditMode(true) }} style={{ width: '3rem' }} src={process.env.PUBLIC_URL + '/images/icons8-edit-100.svg'} />
                <img onClick={update} style={{ width: '2.75rem' }} src={process.env.PUBLIC_URL + '/images/icons8-update-100.svg'} />
                <img onClick={update} style={{ width: '2.5rem' }} src={process.env.PUBLIC_URL + '/images/icons8-trash-100.svg'} />
            </div>
        </td>
    </tr>}
        {isEditMode && <tr style={{ background: ( item.isScheduled ? scheduledRowColor :index % 2 === 0 ? '#f0f8ff' : '#fff') }}>
            <th scope="row" style={{ height: '5rem' }}>{item.id}</th>
            <td style={{ padding: '0 5px 0 5px' }}><InputForm value={title} onChange={setTitle} /></td>
            <td style={{ padding: '0 5px 0 5px' }}><SelectInputForm options={daysOptions} value={dayInWeek} onChange={setDayInWeek} /></td>
            <td> <div className="form-group" style={{ marginTop: '3px' }}>
                <UsersAutocomplete initialValue={{ name: rabbi.name, value: rabbi.value }}
                    handleUserClicked={(e) => { setRabbi(e) }} />
            </div>
            </td>
            <td>
                <div className="form-group" style={{ marginTop: '3px' }}>
                    <UsersAutocomplete initialValue={{ name: user.name, value: user.value }}
                        handleUserClicked={(e) => { setUser(e) }} />
                </div>
            </td>
            <td>
                <div className="form-group">
                    <TimePicker onChange={setTime} value={time} />
                </div>
            </td>
            <td><SelectInputForm options={languagesOptions} value={language} onChange={setLanguage} /></td>
            <td>
                <img onClick={() => { setIsEditMode(false) }} style={{ width: '3rem' }} src={process.env.PUBLIC_URL + '/images/icons8-cancel-100.svg'} />



            </td>
        </tr>}
    </>)
}