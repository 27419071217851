import React,{useEffect,useState,useContext} from 'react';
import { useSelector,useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { LessonsLecurersForm } from '../../components/forms/lessonslecurers-form';
import Modal from '../../components/modals/modal';
import { LessonLecurerRow } from '../../components/tables/LessonsLecurerRow';
import { getLessonslecturers,addLessonGiver } from '../../redux/reducers/lessons.lecturer.reducer';
import { RouteContext } from '../../contexts/route.context';
import { pages } from '../../utils/pages.util';



export const LessonsLecturers = () => {

    

    const dispatch = useDispatch();
    const lessonsLecturers = useSelector(state=>state.lessonsLecturers);
    const [shouldOpenModal,setShouldOpenModal] = useState(false);
    const onRouteChanged = useContext(RouteContext).onRouteChanged;


    useEffect(()=>{
        dispatch(getLessonslecturers())
        onRouteChanged(pages['lessonsGiversList'])
    },[])

    useEffect(()=>{
        console.log(lessonsLecturers)
    },[lessonsLecturers])
    const getRows = () => {
        return lessonsLecturers.data.map((item, index) => <LessonLecurerRow key={index} item={item} index={index} />)
    }
    const onAdd = () =>{
        setShouldOpenModal(true);
    }
    const onSubmit = (data,userId) =>{
        dispatch(addLessonGiver({data,userId}))
    }
    return (<>
        <Modal shouldOpen={shouldOpenModal} onClose={()=>{setShouldOpenModal(false)}}>
            <LessonsLecurersForm onSubmit={onSubmit} />
        </Modal>
        <ul className="table table-stripped main-list" >


            <li >
                <div style={{ display: 'flex', flexDirection: 'row', height: '5rem', justifyContent: 'stretch', alignItems: 'center', color: "#fff",padding:'0 5px 0 5px' }}>
                    <label style={{display:'inline-block',width:'2.8rem'}}>&nbsp;</label>
                    <label style={{flex:1}}>שם</label>
                    <label style={{flex:1}}>מיומנות</label>
                    <label style={{flex:1}}>הערות</label>
                </div>
            </li>
            <ul className="table table-stripped" style={{ listStyle: 'none' }}>

                {getRows()}
            </ul>

        </ul>
        <button className="button-circle" onClick={onAdd}>
            <FontAwesomeIcon icon={faPlus} />
        </button>
    </>)
}