
import _ from 'underscore';
class ArrayUtil {
    static removeItemById(array,id){
        const index = _.findIndex(array,{id});
        return [...array.slice(0,index ),...array.slice(index + 1, array.length)];
    }
    static replaceItemById(array,id,newItem){
        const index = _.findIndex(array,{id});
        array[index] = newItem;
        return [...array];
    }
    static removeItemByField(array,value,propertyName){
        let arr = [];
        array.forEach(element => {
            if(element[propertyName] !== value){
                arr.push(element)
            }
        });
         return arr;   
     }

    static getItemById(array,id){
       const index=  _.findIndex(array,{id});
       if(index > -1){
           return array[index];
       }

       return null;
    }
    static getItemByField(array,field,value){
        var obj = {};
        obj[field] = value;
        const index=  _.findIndex(array,obj);
        if(index > -1){
            return array[index];
        }
 
        return null;
     }
    static getIndexById(array,id){
        return _.findIndex(array,{id});
     }

     static updateById(array,obj){
        const index = _.findIndex(array,{id:obj.id});
        array[index] = obj;
        return array;
     }
}

export default ArrayUtil;