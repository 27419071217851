import axios from "axios";
import Cookies from 'js-cookie';

let instance = undefined;

export const axiosInstance = {

    getInstance: () => {
      if(instance){
        return instance;
      }else{
         instance = axios.create({
          baseURL: '',
          withCredentials: true
        });
        if(localStorage.getItem('secret_token')){
          axiosInstance.setToken(localStorage.getItem('secret_token'))
        }
      }
      return instance ;
  
  },
  setToken:(token)=>{
      instance.defaults.headers.common['Authorization'] =  `Bearer ${token}`; 
  
  }
}