import React,{useEffect, useState} from 'react';
import { useDispatch,useSelector } from 'react-redux';
import THead from '../../components/tables/thead';
import GroupRow from '../../components/tables/GroupRow';
import { getGroups,addGroup,updateGroup } from '../../redux/reducers/groups-reducer';
import Modal from '../../components/modals/modal';
import GroupsForm from '../../components/forms/groups-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'

export const Groups = () =>{

    const dispatch = useDispatch();
    const groups = useSelector(state => state.groups);
    const [shouldOpenModal,setShouldOpenModal] =useState(false);
    const [groupItem,setGroupItem] =useState({});
   

    const onAdd = () =>{
        setGroupItem(null);
        setShouldOpenModal(true);
      }

    const onEdit = (index) =>{
        setGroupItem(groups.data[index]);
        setShouldOpenModal(true);
    }

    const onRemove = () =>{

    }
    const onSubmit = (item) =>{
        if(item.id){
            dispatch(updateGroup(item));
        }else{
            dispatch(addGroup(item))
        }
    } 

    const getGroupsRows = () =>{
        if(groups.data){
            return groups.data.map((group,index)=>{
            return <GroupRow 
                    key={index} 
                    item={group} 
                    index={index} 
                    onEdit={onEdit} 
                    onRemove={onRemove}  />})
        }
    }
    useEffect(()=>{
        dispatch(getGroups())
    },[])

   
    return(<div>
        <Modal shouldOpen={shouldOpenModal} title={'טופס קבוצות'} width={'40rem'} onClose={() => { setShouldOpenModal(false) }}>
  
            <GroupsForm item={groupItem} onSubmit={onSubmit} />
        </Modal>
        <table  className="table table-stripped" style={{ width: '85%', margin: '10vh auto', fontSize: '1.6rem', background: '#fff', boxShadow: '1px 1px #888888' }}>
            <tbody>
                {getGroupsRows()}
            </tbody>
        <THead headers={['מספר מזהה','שם','תיאור','סטטוס',""]} headerColor={'rgb(215 237 253)'} />
        </table>
        <button className="button-circle" onClick={onAdd}>
      <FontAwesomeIcon icon={faPlus} />
    </button>
    </div>)
}