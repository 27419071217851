import React,{useState,useEffect} from "react";

export const Pagination = ({pages,onPageClicked,currentPage}) =>{

   
    const [firstNum,setFirstNum] = useState(1);
    const [secondNum,setSecondNum] = useState(2);
    const [thirdNum,setThirdNum] = useState(3);
    const [forthNum,setForthNum] = useState(4);
    const [fifthNum,setFifthNum] = useState(5);

    const goBack = () =>{
        if(currentPage > 1){
        onPageClicked(currentPage - 1);
        }
    
        
    }
    const goNext = () =>{
        if(currentPage < pages){
        onPageClicked(currentPage + 1);
        }
      
       
    }

    useEffect(()=>{
        if(currentPage === 1 || currentPage === 2 || currentPage === pages - 1  ){
            // setFirstLeftNum(currentPage);
            // setSecondLeftNum(currentPage + 1);
            // setMiddleNum(currentPage + 2);
            // setFirstRightNum(currentPage + 3);
            // setSecondRightNum(currentPage + 4);
        }else if(currentPage < pages - 1){
            setThirdNum(currentPage);
            setFirstNum(currentPage - 2);
            setSecondNum(currentPage - 1);
            setForthNum(currentPage + 1);
            setFifthNum(currentPage + 2);
        }
    },[currentPage])


    return(<nav aria-label="..." style={{textAlign:'center'}}>
    <ul className="pagination">
    { pages > 5 &&  <li className={"page-item" + (currentPage === 1 ? " disabled" : "")}>
        <a className="page-link" href="#" onClick={goBack} tabIndex="-1">Previous</a>
      </li>}
   { pages >= 2 && <> <li className={"page-item" + (firstNum === currentPage ?  " active" : "")} onClick={()=>{onPageClicked(firstNum)}}><a className={"page-link"} >{firstNum}</a></li>
      <li className={"page-item" + (secondNum === currentPage ?  " active" : "") } onClick={()=>{onPageClicked(secondNum)}}><a className="page-link" >{secondNum}</a></li> </>}
    { pages >=3 &&   <li className={"page-item" + (thirdNum == currentPage ?  " active" : "" )} onClick={()=>{onPageClicked(thirdNum)}}>
        <a className="page-link" >{thirdNum} <span className="sr-only">(current)</span></a>
      </li> }
     { pages >=4 && <li className={"page-item" + (forthNum === currentPage ?  " active" : "")} onClick={()=>{onPageClicked(forthNum)}}><a className="page-link" >{forthNum}</a></li>}
      { pages >= 5 && <li className={"page-item" + (fifthNum === currentPage ?  " active" : "")} onClick={()=>{onPageClicked(fifthNum)}}><a className="page-link" >{fifthNum}</a></li>}
      { pages > 5 && <li className={"page-item" + (currentPage === pages ? " disabled" : "")}>
        <a className="page-link"  onClick={goNext}>Next</a>
      </li>}
    </ul>
  </nav>)
}