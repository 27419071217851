import React,{useState,useEffect} from "react";
import { useDispatch,useSelector } from "react-redux";
import AutocompleteServer from "./autocompleteServer";

import { getUsersByName } from "../../../redux/reducers/users-reducer";

export const UsersAutocomplete = ({handleUserClicked,initialValue}) =>{
    const dispatch = useDispatch();
    const users = useSelector(state=>state.users)

    const [usersList,setUsersList] = useState([])


  
    const onUserChanged = (e) => { 
        dispatch(getUsersByName(e))
    }

    useEffect(()=>{
        if(users && users.data){
          const list = users.data.map(u=>{return {name:`${u.firstName} ${u.lastName}`,value:u.id}});
          setUsersList(list);
        }
      },[users])

    return( <><AutocompleteServer list={usersList} initialValue={initialValue} className="advertisement__form-users" onClick={handleUserClicked} onChange={onUserChanged} />
    </>
    );
}