import React, { useState, useEffect } from 'react';
import { useDispatch,useSelector } from "react-redux";
import DayPickerInput from 'react-day-picker/DayPickerInput';
import DateTimePicker from 'react-datetime-picker';
import 'react-day-picker/lib/style.css';

import FormInput from "./input-form";
import InputDateForm from './input-date-form';
import ButtonForm from './button-form';
import TextareaForm from './textarea-from';
import AutocompleteServer from './autocomplete/autocompleteServer';
import { getUsersByName } from '../../redux/reducers/users-reducer';
import SelectInputForm from './select-input-form';
import { Languages } from '../constants/languages.consts';
import ArrayUtil from '../../utils/array-util';
import ValidatorUtil from '../../utils/validator-util'

const LessonForm = (props) => {

  const { item } = props;
  const dispatch = useDispatch();
  const users = useSelector(state=>state.users)

;
  const [title, setTitle] = useState('');
  const [titleError, setTitleError] = useState(null);
  const [subtitle, setSubtitle] = useState('');
  const [time, setTime] = useState(!!item && item.time ? new Date(item.time) : new Date());
  const [rabbi,setRabbi] =  useState(!!item && !!item.rabbi ? item.rabbi : null);
  const [rabbiError,setRabbiError] = useState(null);
  const [languageId,setLanguageId] = useState(!!item ? item.languageId : null);
  const [usersList,setUsersList] = useState([])
  const [languagesOptions,setLanguagesOptions] = useState([]);
  let isFormValid = true;
 

  const onSubmit = (e) => {
    e.preventDefault();
    
    validateForm();
    if(isFormValid){
      const obj = { id: item.id, title, subtitle, time: time,languageId,userId:rabbi.id}
      if (props.item) {
        obj.id = item.id
       
      }
      props.onSubmit(obj)
  }
}

  const handleUserClicked = (item)=>{
    setRabbi({name:item.name,id:item.value})
  }

  const validateForm = () =>{
    isFormValid = true;
    if(ValidatorUtil.isEmptyField(title)){
      setTitleError('שדה חובה')
      isFormValid = false;
    }else{
      setTitleError(null)
    }
    if(ValidatorUtil.isEmptyField(rabbi) ){
      setRabbiError('שדה חובה')
      isFormValid = false;
    }else{
      setRabbiError(null);
    }
  }
  const onUserChanged = (e) => { 
      dispatch(getUsersByName(e))
  }
  const onLanguageChanged = ({value}) =>{
    setLanguageId(value)
  }

  const getSelectedLangauge = (id) =>{
    if(languagesOptions){
      return ArrayUtil.getItemByField(languagesOptions,"value",id)
    }
  
  }
  useEffect(() => {
    if (item) {
      setTitle(item.title);
      setSubtitle(item.subtitle);
      setTime(item.time || new Date());
      setRabbi(!!item && !!item.rabbi ? item.rabbi : null);
      setLanguageId(item.languageId)
      //setEndDate(item.end_date);

    }else{
      setTitle(null);
      setSubtitle(null);
      setTime(new Date());
      setRabbi(null);
      setLanguageId(null);
    }
  }, [item])

  useEffect(()=>{
    if(users && users.data){
      const list = users.data.map(u=>{return {name:`${u.firstName} ${u.lastName}`,value:u.id}});
      setUsersList(list);
    }
  },[users])

  
  useEffect(()=>{
     const options = Object.keys(Languages).map((key)=>{ return {value:Languages[key].value,label:Languages[key].label}})
     setLanguagesOptions(options)
  },[])


  return (<form style={{ padding: '15px',width:'40rem' }} className="advertisement__form">
    <FormInput
    className="advertisement__form-title"
      field={"title"}
      value={title}
      errorMessage={titleError}
      title={"הזן כותרת"}
      onChange={setTitle}
    />
      <FormInput
    className="advertisement__form-subtitle"
      field={"subtitle"}
      value={subtitle}
      title={"הזן כותרת משנה"}
      onChange={setSubtitle}
    />
    <SelectInputForm 
    className={'advertisement__form-lang'}
    title="שפה"
    value={!!languageId ? getSelectedLangauge(languageId):null}
    options={languagesOptions}
    onChange={onLanguageChanged}
    />
  <div className="advertisement__form-rabbi form-group">
    <label>הזן את שם הרב</label>
    <AutocompleteServer 
      list={usersList} 
      className="advertisement__form-users" 
      onClick={handleUserClicked} 
      initialValue={rabbi ? {value:rabbi.id,name:rabbi.name || (rabbi.firstName + ' ' + rabbi.lastName)}:null} 
      errorMessage={rabbiError}
      onChange={onUserChanged} />
  </div>
    <div  className="advertisement__form-date form-group">
    <label>הזן תאריך ושעה</label>
     <DateTimePicker className={"advertisement__form-datepicker"} onChange={(e)=>{console.log(e);setTime(e)}} value={time} />
     </div>
 
 

    <ButtonForm
    className="advertisement__form-btn"
      onSubmit={onSubmit}
      text={'שלח'}
    />

  </form>
  )
}

export default LessonForm;