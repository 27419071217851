import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash,faEdit } from '@fortawesome/free-solid-svg-icons'
import {Link} from 'react-router-dom';

import DateFormat from '../../utils/date-format-util';

const GroupRow = (props) =>{
    const {item,index,onEdit,onRemove} = props;
    return(<tr>
        <td>{item.id}</td>
        <td>{item.name}</td>
        <td>{item.description}</td>
        <td>{item.isActive ? 'פעיל':'לא פעיל'}</td>
        <td>
        <span style={{padding:'10px',boxShadow:'1px 1px 1px'}} onClick={()=>{ onEdit(index);}}>
            <FontAwesomeIcon style={{cursor:'pointer'}} icon={faEdit} />
        </span>
        <span style={{padding:'10px',boxShadow:'1px 1px 1px'}} onClick={()=>{onRemove(index);}}>
            <FontAwesomeIcon style={{cursor:'pointer'}}  icon={faTrash} />
        </span>
    </td>
    </tr>)
}

export default GroupRow;