import React from "react";

export const Cookies = () =>{
    return(<div style={{ animation: "fadeInAnimation ease 2s",
    animationIterationCount: 1,
    animationFillMode: "forwards"}}>
    <h2>
            Cookies
        </h2>
        <p>
            Our site makes limited use of cookies. "Cookies" are small text files that are stored on a local computer hard drive that contain information related to your use of a site. A cookie can't read data off of your computer hard disk or read cookie files created by other Web sites. Cookies do not damage your system. Cookies help websites provide visitors with customized features. Our site uses cookies to deliver content specific to your interests, location, and for other purposes. You can choose whether to accept cookies by changing the settings of your Internet browser. You can reset your browser to refuse all cookies, or allow your browser to show you when a cookie is being sent. If you choose not to accept these cookies, your experience at our site may be diminished and some features may not work as intended.
        </p>
    </div>)
}