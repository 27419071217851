import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import THead from "../../components/tables/thead";
import { getLessonsSchedule, fetchLessonsSchedule, updateLessonScheduledStatus } from "../../redux/reducers/weeklyLessons-reducer";
import { WeeklyLessonRow } from "../../components/tables/weeklyLessonRow";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'

import { HebrewCalendar } from '@hebcal/core';
import SelectInputForm from "../../components/forms/select-input-form";
import Modal from "../../components/modals/modal";
import ButtonForm from "../../components/forms/button-form";
import { WeeklyLessonForm } from "../../components/forms/weeklyLessonForm";
import { addLesson,getLessonsByWeekId, updateLesson } from "../../redux/reducers/lessons-reducer";
import {  toast } from 'react-toastify';

let weeks = [];
let events = null;

export const WeeklyLessonsList = () => {

    const [showWeeklyParasha, setShowWeeklyParasha] = useState(false);
    const [showWeeklyLessonForm, setShowWeeklyLessonForm] = useState(false);
    const [date, setDate] = useState(null);
    const [weekId, setWeekId] = useState(null);
    const [hDate, setHDate] = useState(null);
    const [lessonData, setLessonData] = useState(null);
    const lessonsSchedule = useSelector(state => state.lessonsSchedule);
    const lessons = useSelector(state => state.lessons);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getLessonsSchedule());
        events = HebrewCalendar.calendar({ sedrot: true, noHolidays: false, locale: 'he', start: new Date(2022, 8, 26), end: new Date(2023, 8, 16) });
        events.forEach((e, i) => {
            weeks.push({ value: i, label: e.render('he-x-NoNikud') })
        })
    }, [])

    useEffect(()=>{
        if(hDate){
            const wId = getWeekId()
            setWeekId(wId)
            dispatch(getLessonsByWeekId(wId))
        }
    },[hDate])

    useEffect(()=>{
        if(lessons && lessons.data){
           dispatch(updateLessonScheduledStatus(lessons.data));
        }
       
    },[lessons])

    const onChange = (data) => {
        
       if(!date){
        toast.error('בחר שבוע',{ position: toast.POSITION.TOP_CENTER })
       }
        dispatch(fetchLessonsSchedule([data]));
        setLessonData(data);
        const lData = {id:0,time:date,subTitle:'',week:getWeekId(),lessonScheduleId:data.id,time:getDateToSend(),title:data.title,userId:data.rabbiId,languageId:data.languageId,participants:[{userId:data.userId}]}
        console.log(lData)
        dispatch(addLesson(lData))
    }
    const getDateToSend = () =>{
        const dateToSend = new Date(date.getFullYear(),date.getMonth(),date.getDate());
        if(lessonData && lessonData.time){
            const hours = +lessonData.time.split(':')[0];
            const minutes = +lessonData.time.split(':')[1];
            dateToSend.setHours(hours);
            dateToSend.setMinutes(minutes);
            }
            return dateToSend;
    }
    const getWeekId = () =>{
        const str = '' + hDate.getFullYear() + hDate.month + hDate.day;
                           const weekNumberId  = +str;
        return weekNumberId;
    }
    const onAdd = () =>{
        setShowWeeklyLessonForm(true)
    }
    
    const getLessonsRows = () => {
        if (lessonsSchedule && lessonsSchedule.data) {
            return lessonsSchedule.data.map((item, index) => <WeeklyLessonRow item={item} isScheduled={false} key={index} isEdit={true} onChange={onChange} />)
        }
    }
    return (<>
        <div className="well" style={{ display: 'flex' }}>

            <div style={{ flex: 1 }}>
            
                    <SelectInputForm
                      
                        options={weeks}
                        onChange={(e) => { 
                            setHDate(events[e.value].getDate());
                            
                        }}
                    />
               
            </div>
            <div style={{ flex: 1 }}>{weekId}</div>
            <div style={{ flex: 1 }}></div>
            <div style={{ flex: 1 }}></div>

        </div>
        <Modal shouldOpen={showWeeklyLessonForm} onClose={() => { setShowWeeklyLessonForm(false) }}>
            <WeeklyLessonForm date={date}  />
        </Modal>
        <Modal shouldOpen={showWeeklyParasha} title={''} onClose={() => { setShowWeeklyParasha(false) }}>
            <div style={{minWidth:'30rem'}}>
                <div >
                    <SelectInputForm
                        title={'בחר שבוע'}
                        options={weeks}
                        onChange={(e) => { const date = events[e.value].getDate().greg();
                            
                            const hours = +lessonData.time.split(':')[0];
                            const minutes = +lessonData.time.split(':')[1];
                            date.setHours(hours);
                            date.setMinutes(minutes);
                          //  date.setDate(date.getDate() + lessonData.dayInWeek)
                            setDate(date)
                        }}
                    />
                </div>
                <div>
                    <ButtonForm text={'שלח'} />
                </div>
            </div>
        </Modal>
        <table className="table table-stripped" style={{ width: '85%', margin: '10vh auto', fontSize: '1.6rem', background: '#fff', boxShadow: '1px 1px #888888' }}>
            <THead
                headers={["מספר מזהה", "כותרת", "יום בשבוע", "שם הרב", "שם המשתתף", "שעה", , "שפה", ""]} headerColor="rgb(215 237 253)" />
            <tbody>
                {getLessonsRows()}
            </tbody>
        </table>
        <button className="button-circle" onClick={onAdd}>
      <FontAwesomeIcon icon={faPlus} />
    </button>
    </>)
}