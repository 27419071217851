import React,{useState,useEffect} from "react";
import { useDispatch,useSelector } from "react-redux";
import { MultiSelect } from "react-multi-select-component";
import TextareaForm from "./textarea-from";
import AutocompleteServer from "./autocomplete/autocompleteServer";
import { getUsersByName } from "../../redux/reducers/users-reducer";
import ButtonForm from "./button-form";

// const options = [
//     { label: "Grapes 🍇", value: "grapes" },
//     { label: "Mango 🥭", value: "mango" },
//     { label: "Strawberry 🍓", value: "strawberry", disabled: true },
//   ];

const options = [
    { label: "הלכה - מתחילים", value: 2 },
    { label: "הלכה - בנונים", value: 3 },
    { label: "הלכה - מתקדמים", value: 4 },
    { label: "חסידות - מתחילים", value: 5 },
    { label: "חסידות - בנונים", value: 6},
    { label: "חסידות - מתקדמים", value: 7 },
    { label: "גמרא - מתחילים", value: 8 },
    { label: "גמרא - בנונים", value: 9 },
    { label: "גמרא - מתקדמים", value: 10 },
    { label: "פרשת שבוע - מתחילים", value: 11 },
    { label: "פרשת שבוע - בנונים", value: 12 },
    { label: "פרשת שבוע - מתקדמים", value: 13 },
]
const languageOptions = [
    { label: "עברית", value: "he" }, 
    { label: "אנגלית", value: "en" }, 
    { label: "ספרדית", value: "sp" }, 
    { label: "צרפתית", value: "fr" }, 
    { label: "רוסית", value: "ru" }, 
]

export const LessonsLecurersForm = ({onSubmit}) =>{

    const [selectedTopics, setSelectedTopics] = useState([]);
    const [rabbi, setRabbi] = useState({name:'asaf',id:0});
    const [selectedLanguages, setSelectedLanguages] = useState([]);
    const [skilled,setSkilled] = useState('');
    const [remark,setRemark] = useState('');
    const [usersList,setUsersList] = useState([]);
    
    const dispatch = useDispatch()
    const users = useSelector(state=>state.users);

    const onUserChanged = (e) => { 
      dispatch(getUsersByName(e))
  }
  const handleUserClicked = (item)=>{
    setRabbi({name:item.name,id:item.value})
  }
  useEffect(()=>{
    if(users && users.data){
      const list = users.data.map(u=>{return {name:`${u.firstName} ${u.lastName}`,value:u.id}});
      setUsersList(list);
    }
  },[users])

  const onSubmitLecturer = ()=>{
    const topicsAndLevels = selectedTopics.map(t=>t.value);
    const languages = selectedLanguages.map(l=>l.value)
    onSubmit({topicsAndLevels,languages,remark},rabbi.id)
    console.log(topicsAndLevels,languages,rabbi)
  }

 

    return (<>
      <div className="form-group">
          <label style={{fontFamily: '"Montserrat", "Helvetica Neue", Helvetica, Arial, sans-serif',alignSelf:'end',fontSize:'1.9rem'}}>הכנס שם משתמש</label>
    <AutocompleteServer
     list={usersList} 
     className="advertisement__form-users" 
     onClick={handleUserClicked} 
     initialValue={rabbi ? rabbi.name : ''} 
     errorMessage={null}
     onChange={onUserChanged} />
  
    </div>
      <div style={{marginTop:'2rem',display:'flex',flexDirection:'column'}}>
      
        {/* <pre>{JSON.stringify(selected)}</pre> */}
        <label style={{fontSize:'1.9rem',alignSelf:'end'}}>בחר נושאים</label>
        <MultiSelect
          options={options}
          value={selectedTopics}
          onChange={setSelectedTopics}
          labelledBy="Select"
        />
      </div>
      <div style={{marginTop:'2rem',display:'flex',flexDirection:'column'}}>
      <label  style={{fontSize:'1.9rem',alignSelf:'end'}}>בחר שפות</label>
      <MultiSelect
      
          options={languageOptions}
          value={selectedLanguages}
          onChange={setSelectedLanguages}
          labelledBy="Select"
        />
      </div>
      {/* <div style={{marginTop:'1rem'}}>
      <InputForm />
      </div> */}
      <TextareaForm 
      labelStyle={{alignSelf:'end',fontFamily: '"Montserrat", "Helvetica Neue", Helvetica, Arial, sans-serif',fontSize:'1.9rem'}} 
      title={'ניסיון והמלצות'}
      onChange={setSkilled}
      value={skilled}
      />
        <TextareaForm 
      labelStyle={{alignSelf:'end',fontFamily: '"Montserrat", "Helvetica Neue", Helvetica, Arial, sans-serif',fontSize:'1.9rem'}} 
      title={'הערות'}
      onChange={setRemark}
      value={remark}
      />
      <ButtonForm text={'שלח'} onSubmit={onSubmitLecturer} />
      </>
    );
  
}