import React, { useState } from "react";
import { InfoList } from "../../components/info-list/info-list";
import { PrivacyPolicyContainer } from "./privacyPolicyContainer";


export const PrivacyPolicy = () => {

    const [sectionName,setSectionName] = useState('');

    return (<>
    
    <div  style={{ padding: '4.5rem 3rem',marginTop:'2.5rem',display:'flex',flexDirection:'row',minHeight:'100%' }}>
        <InfoList onClick={setSectionName} />
        <div className="info-main" style={{flex:9,padding:'0 5rem'}}>
        <PrivacyPolicyContainer sectionName={sectionName} />
</div>  </div></>)
}