import React from "react";

const LessonParticipantRow  = ({item}) =>{
    return(<tr>
        <td style={{width:'10%'}}>{item.user.id}</td>
        <td style={{width:'25%'}}>{item.user.firstName + '  ' + item.user.lastName}</td>
        <td style={{width:'25%'}}>{item.user.email}</td>
        <td></td>
    </tr>)
}

export default LessonParticipantRow;