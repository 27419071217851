import React,{useState,useEffect} from 'react';

const CheckboxFrom = ({title,className,onChange,value,style={}}) =>{
    const [isChecked,setIsChecked] = useState(true);

    useEffect(()=>{
      setIsChecked(value);
    
    },[value])
    return(<div  className={"form-group" + (className ? " " + className : "")}><label className="checkbox__container">
    <input type="checkbox" readOnly checked={isChecked} name="radio"  onClick={()=>{onChange(!isChecked);setIsChecked(!isChecked);}} />
    {title}
    <span className="checkmark"></span>
  </label></div>)
}

export default CheckboxFrom;