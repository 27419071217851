import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { axiosInstance} from "../../axiosInstance";
import {toast} from 'react-toastify'

const BASE_URL = "/api/v1/lessonsschedule/";

export const getLessonsSchedule = createAsyncThunk(
    'lessonsschedule/get',
    async () => {
      try {
        const response = await axiosInstance.getInstance().get(BASE_URL);
      
        return response.data
      } catch (err) {
        console.log(err)
        return "error"
      }
    }
  )

  export const addLessonSchedule = createAsyncThunk(
    'lessonsschedule/add',
    async (lessonSchedule) => {
      try {
        const response = await axiosInstance.getInstance().post(BASE_URL,lessonSchedule)
        toast("הוספת רשומה לשיעורים השבועיים בוצעה בהצלחה",{ position: toast.POSITION.TOP_CENTER })
      
        return response.data
      } catch (err) {
        toast.error("הוספת רשומה לשיעורים השבועיים נכשלה",{ position: toast.POSITION.TOP_CENTER })
        console.log(err)
        return "error"
      }
    }
  )
 
export const lessonsScheduleSlice = createSlice({
    name: "lessonsschedule",
    initialState: {
      data: [],
      dataToSend:[],
      loading:false
    },
    reducers: {
    fetchLessonsSchedule(state, action) {
        state.dataToSend = action.payload

      },
      updateLessonScheduledStatus(state, {payload}) {

        state.data = state.data.map(d => (Object.assign({...d},{isScheduled:payload.find(i => i.lessonScheduleId === d.id) !== undefined}))) 


      }
    },
    extraReducers: (builder) => {
        builder.addCase(getLessonsSchedule.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.data = payload;
            state.dataToSend = payload.map(obj =>({id:obj.id,time:obj.time,rabbiId:obj.rabbi.id,dayinWeek:obj.dayInWeek,languageId:obj.languageId}))
        })
        builder.addCase(getLessonsSchedule.pending, (state, { payload }) => {
            state.loading = true;
        })
        builder.addCase(getLessonsSchedule.rejected, (state, { payload }) => {
            state.loading = false;
        })
        builder.addCase(addLessonSchedule.fulfilled, (state, { payload }) => {
          state.loading = false;
          state.data.push(payload);
          const obj = {id:payload.id,time:payload.time,rabbiId:payload.rabbi.id,dayinWeek:payload.dayInWeek,languageId:payload.languageId}
          state.dataToSend.push(obj)
      })
      builder.addCase(addLessonSchedule.pending, (state, { payload }) => {
          state.loading = true;
      })
      builder.addCase(addLessonSchedule.rejected, (state, { payload }) => {
          state.loading = false;
      })
       
       
      }
  })
  export const { fetchLessonsSchedule ,updateLessonScheduledStatus} = lessonsScheduleSlice.actions




