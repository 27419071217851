import React,{useEffect, useState} from 'react';
import {useDispatch,useSelector} from 'react-redux';
import { Document, Page } from 'react-pdf';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { Pagination } from '../../components/tables/pagination';

import { LessonsReferencesList } from './lessonsReferencesList';
import { LessonReferenceEditor } from './lessonReferenceEditor';
import LessonReferenceRow from '../../components/tables/LessonReferenceRow';
import Modal from '../../components/modals/modal';
import { getLessonsReferences,getLessonsReferencesByCategory,addLessonsReferences,addLessonsReferencesContent,updateLessonsReferences, deleteLessonsReferences } from '../../redux/reducers/lessonReferences-reducer';
import { getLessonsSubCategories } from '../../redux/reducers/lessonsSubCategories-reducer';
import LessonsReferencesForm from '../../components/forms/lessonsReferencesForm';
import { getLessonsSubCategoriesByName } from '../../redux/reducers/lessonsSubCategories-reducer';
import SelectInputForm from '../../components/forms/select-input-form';
import { getLessonsCategories } from '../../redux/reducers/lessonsCategories.reducer';



const LessonsReferences = () =>{

    
    const dispatch = useDispatch();
  
    const [shouldShowModal,setShouldShowModal] = useState(false);
    const [lessonSubSubjects,setLessonsSubSubjects] = useState([])
    const [lessonSubjects,setLessonsSubjects] = useState([])
    const [numPages, setNumPages] = useState(null);
    const [numPagesArr, setNumPagesArr] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [item,setItem] = useState(null);
    const [pdfUrl,setPdfUrl] = useState(null);
    const [activeTab,setActiveTab] = useState(1);
    const lessonsReferences = useSelector(state => state.lessonsReferences)
    const lessonsSubCategories = useSelector(state=>state.lessonsSubCategories)
    const lessonsCategories = useSelector(state=>state.lessonsCategories)


    const onSubmit = (formData) =>{
     
        if(formData.id){
            dispatch(updateLessonsReferences(formData))
        }else{
            dispatch(addLessonsReferences(formData))
        }
    }
    
    const onSubmitContent = (obj) =>{
     
            if(obj.id){
                dispatch(updateLessonsReferences(obj))
            }else{
            dispatch(addLessonsReferencesContent(obj))
            }
        
    }

    const onEditClicked = (index) =>{
        setItem(lessonsReferences.data[index]);

    }
   

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
      
      }

      const onCategoryChanged = (e) => { 
        dispatch(getLessonsSubCategoriesByName(e))
    }
    const onLessonCategoryChanged = (e) =>{
        dispatch(getLessonsReferencesByCategory(e.value))
    }

    const onRemoveClicked = (index) =>{
        const id = lessonsReferences.data[index].id
        dispatch(deleteLessonsReferences(id))
    }

    const onPageChange = (pageNum) =>{
        dispatch(getLessonsReferences({pageNum,countPerPage:50}))
    }
    useEffect(()=>{
      
        dispatch(getLessonsReferences({pageNum:1,countPerPage:50}))
        dispatch(getLessonsSubCategories())
        dispatch(getLessonsCategories())
        
    },[])

    useEffect(()=>{
        if(item){
            if(item.html){
                setActiveTab(2)
            }else{
                setShouldShowModal(true);
            }
           
        }
    },[item])
  
    useEffect(()=>{
        if(lessonsSubCategories && lessonsSubCategories.data){
            const data = lessonsSubCategories.data.map((item)=>{return {value:item.id,label:item.name}})
            setLessonsSubSubjects(data)
           
        }
    },[lessonsSubCategories])

    useEffect(()=>{
        if(lessonsCategories && lessonsCategories.data){
            const data = lessonsCategories.data.map((item)=>{return {value:item.id,label:item.name,subCategories:item.lessonSubCategories.map((item)=>{return {value:item.id,label:item.name}})}})
            setLessonsSubjects(data)
           
        }
    },[lessonsCategories])

    useEffect(()=>{
        let arr = [];
        for(let i = 1; i<= numPages;i++){
            arr.push(i);
        }
        setNumPagesArr(arr);
        
    },[numPages])

    useEffect(()=>{
        if(lessonsReferences.pageNum){
            setCurrentPage(lessonsReferences.pageNum);
        }
    },[lessonsReferences.pageNum])

    return(<div>
        <Modal shouldOpen={pdfUrl !== null} onClose={()=>{setNumPages(0) ;setPdfUrl(null)}}>
        <Document file={{url:pdfUrl}} onLoadError={(e)=>{console.log(e)}} onLoadSuccess={onDocumentLoadSuccess}>
        {numPagesArr.map((item)=><Page key={item} pageNumber={item} />)}
        
        
      </Document>
   {numPages}
        </Modal>
      
         <Modal shouldOpen={shouldShowModal} onClose={()=>{setItem(null);setShouldShowModal(false)}}>
            <LessonsReferencesForm  list={lessonsSubCategories.data} onCategoryChanged={onCategoryChanged} className="textarea-md" onSubmit={onSubmit} item={item} lessonSubjects={lessonSubjects} lessonSubSubjects={lessonSubSubjects} />
        </Modal>
        <ul className="nav nav-tabs nav-justified">
  <li className={(activeTab === 1 ? "active" : "")} onClick={()=>{setActiveTab(1);setItem(null)}} ><a  aria-current="page" href="#">קובץ</a></li>
  <li className={(activeTab === 2 ? "active" : "")} onClick={()=>{setActiveTab(2)}}><a  href="#">עורך</a></li>

</ul>
        {activeTab === 1 && <LessonsReferencesList data={lessonsReferences.data} 
                currentPage={currentPage}
                setShouldShowModal={setShouldShowModal} 
                onEditClicked={onEditClicked} 
                onViewFileClicked={setPdfUrl}
                lessonSubSubjects={lessonSubSubjects}
                totalPages={lessonsReferences.totalPages}
                onLessonCategoryChanged={onLessonCategoryChanged}
                onRemoveClicked={onRemoveClicked} onPageChange={onPageChange} setItem={setItem} />}
        {activeTab === 2 &&<LessonReferenceEditor item={item} lessonSubjects={lessonSubjects} lessonSubSubjects={lessonSubSubjects} onSubmit={onSubmitContent} />}
    </div>)
}

export default LessonsReferences;