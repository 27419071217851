import React, { useState, useEffect } from 'react';

import FormInput from "./input-form";
import TextareaForm from './textarea-from';
import FileInputForm from './file-input-form';
import SelectInputForm from './select-input-form';
import ArrayUtil from '../../utils/array-util';
import AutocompleteServer from './autocomplete/autocompleteServer';
import ValidatorUtil from '../../utils/validator-util';
import { errorMessages } from '../../utils/error-messages-util';
import { Languages } from '../constants/languages.consts';

const LessonsReferencesForm = (props) => {

  const { item } = props;
  const [title, setTitle] = useState('');
  const [titleError, setTitleError] = useState('');
  const [subtitle, setSubtitle] = useState('');
  const [lessonSubSubjects, setLessonSubSubjects] = useState([]);
  const [subtitleError, setSubtitleError] = useState('');
  const [file, setFile] = useState(null);
  const [fileError, setFileError] = useState(null);
  const [subCategory, setSubCategory] = useState(0);
  const [category, setCategory] = useState(0);
  const [subCategoryError, setSubCategoryError] = useState('');
  const [languagesOptions,setLanguagesOptions] = useState([]);
  const [languageId,setLanguageId] = useState(!!item ? item.languageId : null);


  const [name, setName] = useState('');




  useEffect(() => {
    if (item) {

      setName(item.name);
      setTitle(item.title);
      setSubtitle(item.subtitle);
      if (props.lessonSubjects) {
        const obj = ArrayUtil.getItemByField(props.lessonSubSubjects, 'value', item.lessonSubCategoryId)
        setSubCategory(obj)
      }

    }

  }, [item])

  useEffect(()=>{
    const options = Object.keys(Languages).map((key)=>{ return {value:Languages[key].value,label:Languages[key].label}})
    setLanguagesOptions(options)
 },[])

  useEffect(() => {
    if (item && props.lessonSubSubjects) {
      const obj = ArrayUtil.getItemByField(props.lessonSubSubjects, 'value', item.lessonSubCategoryId)
      setSubCategory(obj)
      const cat = {value:item.lessonCategory.id,label:item.lessonCategory.name}
      setCategory(cat)
    }
  }, [props.lessonSubSubjects])

  useEffect(() => {
    if(props.lessonSubjects){
     
    if (item ) {
 
     const lsc = props.lessonSubjects.find(l => l.value === item.lessonCategory.id);
     setLessonSubSubjects(lsc.subCategories);
    }else{
      setLessonSubSubjects(props.lessonSubjects[0].subCategories)
    }
  }
  }, [props.lessonSubjects])

  const onLanguageChanged = ({value}) =>{
    setLanguageId(value)
  }

 const onCategoryChanged = (e) =>{
      var lessonSubjects = props.lessonSubjects.find(ls => ls.value  === e.value);
      setLessonSubSubjects(lessonSubjects.subCategories);
      setCategory(e);
      setSubCategory(lessonSubjects.subCategories[0])
 }
  

  const getSelectedLangauge = (id) =>{
    if(languagesOptions){
      return ArrayUtil.getItemByField(languagesOptions,"value",id)
    }
  
  }
  const isFormValid = () => {
    let result = true
    if (ValidatorUtil.isEmptyField(title)) {
      setTitleError(errorMessages.fieldRequired)
      result = false;
    } else {
      setTitleError('')

    }
    if (subCategory === null || subCategory === 0) {
      setSubCategoryError(errorMessages.chooseSelection)
      result = false;
    } else {
      setSubCategoryError('')

    }
    if(file === null && !item){
      result = false;
      setFileError(errorMessages.fileFieldError)
    }else{
      setFileError('')
    }
    return result;
  }
  const prepareFormObject = () =>{

    if (item && item.id) {
      
        return {id:item.id,
          title,
          subtitle,
          lessonCategoryId:category.value,
          lessonSubCategoryId:subCategory.value,
          languageId }
    
    }else{
      let formData = new FormData();
      formData.append('file', file);
      formData.append('title', title);
      formData.append('subtitle', subtitle);
      formData.append('lessonCategoryId', category.value);
      formData.append('lessonSubCategoryId', subCategory.value);
      formData.append('languageId', languageId);
      return formData;
     
    }
  }
  const onSubmit = (e) => {
    e.preventDefault();
    if (isFormValid()) {
     const formObj = prepareFormObject();
      props.onSubmit(formObj)
    }
  }

  return (<form style={{ padding: '15px' }}>
    <FormInput
      field={"title"}
      title={"הזן שם כותרת"}
      value={title}
      onChange={setTitle}
      errorMessage={titleError}
    />
    <TextareaForm
      field={"subtitle"}
      title={"הזן כותרת משנה"}
      value={subtitle}
      onChange={setSubtitle}
      className={props.className}
    />
    {/* <AutocompleteServer
               
              
               onClick={handleUserClicked} 
               initialValue={subCategory || null} 
           
               onChange={onUserChanged} 
             title={'בחר קטגוריה'}
                value={subCategory}
              
                list={props.list}
             /> */}
              <SelectInputForm 
    className={'advertisement__form-lang'}
    title="שפה"
    value={!!languageId ? getSelectedLangauge(languageId):null}
    options={languagesOptions}
    onChange={onLanguageChanged}
    />

{props.lessonSubjects && props.lessonSubjects.length > 0 && <SelectInputForm
      title={'בחר קטגוריה'}
      value={category}
      onChange={onCategoryChanged}
      options={props.lessonSubjects}
      errorMessage={subCategoryError}
    />}
    {lessonSubSubjects && lessonSubSubjects.length > 0 && <SelectInputForm
      title={'בחר תת קטגוריה'}
      value={subCategory}
      onChange={setSubCategory}
      options={lessonSubSubjects}
      errorMessage={subCategoryError}
    />}
    <FileInputForm
      title={"העלה קובץ pdf"}
      onChange={setFile}
      errorMessage={fileError}
    />

    <button className="btn btn-primary" onClick={onSubmit} >עדכן</button>
  </form>
  )
}

export default LessonsReferencesForm;