import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { axiosInstance } from "../../axiosInstance";
import ArrayUtil from "../../utils/array-util";

const BASE_URL = '/api/v1/faqs/';

export const getFaqs = createAsyncThunk(
  'faqs/get',
  async () => {
    try {

      const response = await axiosInstance.getInstance().get(BASE_URL)

      return response.data
    } catch (err) {
      console.log(err)
    }
  }
)
export const getFaqById = createAsyncThunk(
  'faqs/getFaqById',
  async (id) => {
    try {

      const response = await axiosInstance.getInstance().get(BASE_URL + id)

      return response.data
    } catch (err) {
      console.log(err)
    }
  }
)
export const addFaq = createAsyncThunk(
  'faqs/addFaq',
  async (faq) => {
    try {

      const response = await axiosInstance.getInstance().post(BASE_URL,faq)

      return response.data
    } catch (err) {
      console.log(err)
    }
  }
)
export const updateFaq = createAsyncThunk(
  'faqs/updateFaq',
  async (faq) => {
    try {

      const response = await axiosInstance.getInstance().put(BASE_URL + faq.id,faq)

      return response.data
    } catch (err) {
      console.log(err)
    }
  }
)

export const faqsSlice = createSlice({
  name: "faqs",
  initialState: {
    data: [],
    currentFaq: null,
    loading: false
  },
  reducers: {
    fetchContacts(state, action) {
      state.data = action.payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getFaqs.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.data = payload;

    })
    builder.addCase(getFaqs.pending, (state, { payload }) => {
      state.loading = true;
    })
    builder.addCase(getFaqs.rejected, (state, { payload }) => {
      state.loading = false;
    })
    builder.addCase(getFaqById.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.currentFaq = payload;

    })
    builder.addCase(getFaqById.pending, (state, { payload }) => {
      state.loading = true;
    })
    builder.addCase(getFaqById.rejected, (state, { payload }) => {
      state.loading = false;
    })
    builder.addCase(addFaq.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.currentFaq = payload;
      state.data = [...state.data,state.currentFaq]

    })
    builder.addCase(addFaq.pending, (state, { payload }) => {
      state.loading = true;
    })
    builder.addCase(addFaq.rejected, (state, { payload }) => {
      state.loading = false;
    })
    builder.addCase(updateFaq.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.currentFaq = payload;
      const index = ArrayUtil.getIndexById(payload.id);
      state.data[index] = payload;
      state.data = [...state.data]

    })
    builder.addCase(updateFaq.pending, (state, { payload }) => {
      state.loading = true;
    })
    builder.addCase(updateFaq.rejected, (state, { payload }) => {
      state.loading = false;
    })

    // add more case
  }
})
export const { fetchContacts } = faqsSlice.actions

