import React,{useState} from 'react';
import { useSelector } from 'react-redux';
import InputForm from '../../components/forms/input-form';
import SelectInputForm from '../../components/forms/select-input-form';


export const DynamicForm = ()=>{
    const str = '[{"title":"enter first name","identifier":"dsfsd_1","type":"string","validation":["required"]},{"title":"enter last name","identifier":"dsfsd_2","type":"string","validation":["required"]},{"title":"enter topics","identifier":"dsfsd_3","type":"multiselect","option":[{"value":1,"title":"halacha"},{"value":2,"title":"mishnah"},{"value":3,"title":"chasidut"}],"validation":["required"]}]'
    const obj =  JSON.parse(str);
    const [arr,setArr] = useState(obj)
    const [data,setData] = useState({});

    
  
    return(<form style={{padding:'10px'}}>{arr.map((o,i)=>{
        const onChange = (identifier) =>{

            return function(value){
                data[identifier] = value;
                console.log(data);
                setData(data)
            }
        }
        if(o.type === 'string'){
            return <InputForm key={i} title={o.title} onChange={onChange(o.identifier)} />
        }
        if(o.type === 'multiselect'){
            const option = o.option.map(v => ({value:v.value,label:v.title}));
            return <SelectInputForm key={i} title={o.title} options={option} onChange={onChange(o.identifier)} />
        }
  
})}</form>)
}