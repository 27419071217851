import React,{useState,useEffect} from 'react';
import { useSelector,useDispatch } from 'react-redux';
import notificationsAction,{SEND_NOTIFICATION} from '../redux/actions/notifications-action';
import THead from '../components/tables/thead';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { NotificationForm } from '../components/forms/notifications-form';
import { getGroups } from '../redux/reducers/groups-reducer';
import Modal from '../components/modals/modal';

const Notifications  = () =>{

    const dispatch = useDispatch();
    const [shouldOpenModal,setShouldOpenModal] = useState(false);
    const notifications = useSelector(state => state.notifications);
    const groups = useSelector(state => state.groups)
    const sendMessage = () =>{
        dispatch(notificationsAction(SEND_NOTIFICATION,{userIds:[1],deliveryId:4}))
    }
    const onAdd = () =>{
        setShouldOpenModal(true);
    }

    const onSubmit = () =>{

    }
    useEffect(()=>{
        dispatch(getGroups())
    },[])

    return(<div>
        <Modal shouldOpen={shouldOpenModal} onClose={()=>{setShouldOpenModal(false)}} width={'450px'}>
            <NotificationForm groups={groups} onSubmit={onSubmit} />
        </Modal>
        <table className="table table-stripped" style={{ width: '75%', margin: '10vh auto', fontSize: '1.6rem', background: '#fff', boxShadow: '0px 0px 8px #888888' }}>
            <THead headers={['מספר מזהה','נושא','תוכן','בתוקף עד']} />
        </table>
        <button className="button-circle" onClick={onAdd}>
      <FontAwesomeIcon icon={faPlus} />
    </button>
        <button onClick={sendMessage}>send message</button>
    </div>)
}

export default Notifications;