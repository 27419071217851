import ArrayUtil from '../../utils/array-util';

import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { axiosInstance} from "../../axiosInstance";
import {toast} from 'react-toastify'

const BASE_URL = "/api/v1/groups"

export const getGroups = createAsyncThunk(
    'groups/get',
    async () => {
      try {
        const response = await axiosInstance.getInstance().get(BASE_URL)
      
        return response.data
      } catch (err) {
        console.log(err)
        return "error"
      }
    }
  )

  

  export const addGroup = createAsyncThunk(
    'groups/add',
    async (notification) => {
      try {
        const response = await axiosInstance.getInstance().post(BASE_URL,notification)
        toast("הוספת קבוצה בוצעה בהצלחה",{ position: toast.POSITION.TOP_CENTER })
        return response.data
      } catch (err) {
        toast.error("הוספת קבוצה נכשל",{ position: toast.POSITION.TOP_CENTER })
        console.log(err)
      }
    }
  )
  export const updateGroup = createAsyncThunk(
    'groups/update',
    async (notification) => {
      try {
        const response = await axiosInstance.getInstance().put(BASE_URL + "/" + notification.id ,notification)
        toast("עידכון קבוצה בוצע בהצלחה",{ position: toast.POSITION.TOP_CENTER })
      
        return response.data
      } catch (err) {
        toast("עידכון קבוצה נכשל",{ position: toast.POSITION.TOP_CENTER })
        console.log(err)
      }
    }
  )

export const groupsSlice = createSlice({
    name: "groups",
    initialState: {
      data: [],
      loading:false
    },
    reducers: {
    fetchUsers(state, action) {
        state.data = action.payload
      }
    },
    extraReducers: (builder) => {
        builder.addCase(getGroups.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.data = payload;
        })
        builder.addCase(getGroups.pending, (state, { payload }) => {
            state.loading = true;
        })
        builder.addCase(getGroups.rejected, (state, { payload }) => {
            state.loading = false;
        })
        builder.addCase(addGroup.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.data = [...state.data,payload];
        })
        builder.addCase(addGroup.pending, (state, { payload }) => {
            state.loading = true;
        })
        builder.addCase(addGroup.rejected, (state, { payload }) => {
            state.loading = false;
        })
        builder.addCase(updateGroup.fulfilled, (state, { payload }) => {
          state.loading = false;
          const arr = ArrayUtil.updateById(state.data,payload)
          state.data = [...arr];
      })
      builder.addCase(updateGroup.pending, (state, { payload }) => {
          state.loading = true;
      })
      builder.addCase(updateGroup.rejected, (state, { payload }) => {
          state.loading = false;
      })
        // add more case
      }
  })
  export const { fetchUsers } = groupsSlice.actions




