import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { axiosInstance} from "../../axiosInstance";

const BASE_URL = '/api/v1/constants/';

export const getTopics = createAsyncThunk(
    'topics/get',
    async () => {
      try {
       
        const response = await axiosInstance.getInstance().get(BASE_URL + "gettopics")
     
        return response.data
      } catch (err) {
        console.log(err)
      }
    }
  )

  export const topicsSlice = createSlice({
    name: "topics",
    initialState: {
        data: [],
      loading:false
    },
    reducers: {
    fetchContacts(state, action) {
        state.data = action.payload
      }
    },
    extraReducers: (builder) => {
        builder.addCase(getTopics.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.data = payload;
         
        })
        builder.addCase(getTopics.pending, (state, { payload }) => {
            state.loading = true;
        })
        builder.addCase(getTopics.rejected, (state, { payload }) => {
            state.loading = false;
        })
        
        // add more case
      }
  })
  export const { fetchContacts } = topicsSlice.actions

