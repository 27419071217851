import React,{useEffect, useState} from 'react';
import { useDispatch,useSelector } from 'react-redux';
import THead from '../../components/tables/thead';
import { UserGroupRow } from '../../components/tables/userGroupRow';
import Modal from '../../components/modals/modal';
import { getUsersGroupsByUserId,updateUserGroup,addUserGroup } from '../../redux/reducers/usersGroups-reducer';
import { getGroups } from '../../redux/reducers/groups-reducer';
import { UserGroupForm } from '../../components/forms/userGroup-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons';

export const UserGroups = (props) =>{

    const dispatch = useDispatch();
    const usersGroups = useSelector(state=>state.usersgroups);
    const groups = useSelector(state=>state.groups);
    const [userGroupItem,setUserGroupItem] = useState(null);
    const [shouldOpenModal,setShouldOpenModal] =useState(false);
    const userId = +props.match.params.userId
    

    useEffect(()=>{
        dispatch(getUsersGroupsByUserId(userId))
        if(!groups.data || groups.data.length === 0 ){
            dispatch(getGroups())
        }
      
    },[])

    const onEdit = (index) =>{
       
        setUserGroupItem(usersGroups.data[index]);
    }
    const getUserGroupsRows = () =>{
        if(usersGroups){
            return usersGroups.data.map((item,index)=><UserGroupRow key={index} index={index} item={item} onEdit={onEdit} />)
        }
    }
    const onAdd = () =>{
        setUserGroupItem(null);
        setShouldOpenModal(true)
    }

    const onSubmit = (e,group,isActive) =>{
        e.preventDefault();
        if(userGroupItem){
           dispatch(updateUserGroup({userId,groupId:group,isActive}));
        }else{
           dispatch(addUserGroup({userId,groupId:group,isActive}))
        }
       
     }

    useEffect(()=>{
        if(userGroupItem){
            setShouldOpenModal(true)
        }
        
    },[userGroupItem])

    return(<div>
          <Modal shouldOpen={shouldOpenModal} title={'טופס קבוצות'} width={'40rem'} onClose={() => { setShouldOpenModal(false); setUserGroupItem(null) }}>
  
  <UserGroupForm item={userGroupItem} groups={groups.data} onSubmit={onSubmit} />
</Modal>
       <table  className="table table-stripped" style={{ width: '85%', margin: '10vh auto', fontSize: '1.6rem', background: '#fff', boxShadow: '1px 1px #888888' }}>
       
            <THead headers={['מספר מזהה','שם קבוצה','סטטוס','']}   headerColor={'rgb(215 237 253)'}/>
            <tbody>
                {getUserGroupsRows()}
            </tbody>
        </table>
        <button className="button-circle" onClick={onAdd}><FontAwesomeIcon icon={faPlus} />

    </button>
    </div>)
}