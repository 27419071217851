import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit,faTrash } from '@fortawesome/free-solid-svg-icons';
import { getlanguageName } from '../../utils/language-util';


const LessonReferenceRow = (props) =>{

    const {item,onViewFileClicked,onCategoriesClicked} = props;

  
    
    return(   <tr><th scope="row" style={{height:'5rem',verticalAlign:'middle'}}>{item.id}</th>
    <td>{item.title}</td>
    <td>{item.subtitle}</td>
    <td>{item.filename}</td>
    <td style={{whiteSpace:'nowrap',maxWidth:'20rem',overflow:'hidden',textOverflow:'ellipsis'}}>{item.text}</td>
    <td>{item.lessonCategory.name}</td>
    <td>{item.lessonSubCategory.name}</td>
    <td>{getlanguageName(item.languageId)}</td>
    <td><img src={process.env.PUBLIC_URL + '/images/pdf.png'} style={{height:'3rem'}} onClick={()=>onViewFileClicked(item.path)} /></td>
   
    <td style={{display:'flex',flexDirection:'row'}}>
        <span style={{padding:'10px'}} onClick={()=>{props.onEdit(props.index);}}>
            <FontAwesomeIcon icon={faEdit} />
        </span>
        <span style={{padding:'10px'}} onClick={()=>{props.onRemove(props.index);}}>
            <FontAwesomeIcon icon={faTrash} />
        </span>
    </td>
    </tr>
    )
}

export default LessonReferenceRow;