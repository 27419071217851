import React from 'react';
import { Route, Routes,BrowserRouter as Router,createBrowserRouter } from 'react-router-dom';
import Login from './../pages/login'
import Users from '../pages/users/users';
import {Groups} from '../pages/groups/groups';
import { UserGroups } from '../pages/usersGroups/usersGroups';
import Contacts from '../pages/contacts';
import LessonsReferences from '../pages/lessonsReferences/lessonsReferences';
import Notifications from '../pages/notification';
import Lessons from '../pages/lessons/lessons';
import LessonParticipants from '../pages/lessons/lessonParticipants';
import { PrivacyPolicy } from '../pages/privacyPolicy/privacyPolicy';
import { Faqs } from '../pages/faqs/faqs';
import { FaqEdit } from '../pages/faqs/faq-edit';
import { WeeklyLessonsList } from '../pages/lessons/weeklyLessonsList';
import { MezuzaActivity } from '../pages/activities/mezuza.activity';
import { LessonsLecturers } from '../pages/lessons/lessons.lecturers';
import { KosherizationActivity } from '../pages/activities/kosherization.activity';
import { DynamicForm } from '../pages/dynamic-form/dynamicForm';


const Main = () =>{
    return(<div className="main">
      
        <Routes>
        <Route path="/" element={<Login />} exact />
        <Route path="/users" element={<Users />} exact />
        <Route path="/lessons" element={<Lessons />} exact />
        <Route path="/groups" element={<Groups />} exact />
        <Route path="/usersgroups/:userId" element={<UserGroups />} exact />
        <Route path="/contacts" element={<Contacts />} exact />
        <Route path="/lessonsReferences" element={<LessonsReferences />} exact />
        <Route path="/notifications" element={<Notifications />} exact />
        <Route path="/privacy" element={<PrivacyPolicy />} exact />
        <Route path="/lessonParticipants/:lessonId" element={<LessonParticipants />} exact />
        <Route path="/faqs" element={<Faqs />} exact />
        <Route path="/faqedit/:faqId" element={<FaqEdit />} />
        <Route path="/faqedit" element={<FaqEdit />} />
        <Route path="/weeklylessonsschedule" element={<WeeklyLessonsList />} />
        <Route path="/activities" element={<MezuzaActivity />} />
        <Route path="/kashrut" element={<KosherizationActivity />} />
        <Route path="/lessonslecurers" element={<LessonsLecturers />} />
        <Route path="/form" element={<DynamicForm />} />
       
        
        </Routes>
      
    </div>);
}

export default Main;