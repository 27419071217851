import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getUsersByFilters,getUsersByName } from "../../redux/reducers/users-reducer";
import { addParticipant, getParticipantsByLessonId } from "../../redux/reducers/lessonsParticipants-reducer";
import THead from "../../components/tables/thead";
import LessonParticipantRowExtended from "../../components/tables/lessonParticipantRowExtended";
import AutocompleteServer from './../../components/forms/autocomplete/autocompleteServer';
import ValidatorUtil from "../../utils/validator-util";

const LessonParticipants = (props) => {

    const lessonId = props.match.params.lessonId;
    const [items, setItems] = useState(null);
    const [user,setUser] = useState('');
    const [usersList, setUsersList] = useState([]);
    const [userError,setUserError] = useState(null);
    const lessonsParticipants = useSelector(state => state.lessonsParticipants);
    const users = useSelector(state => state.users)
    const dispatch = useDispatch();



    useEffect(() => {
        if (lessonsParticipants && lessonsParticipants.data) {
            setItems(lessonsParticipants.data)
        }
    }, [lessonsParticipants])

    useEffect(() => {
        
        dispatch(getParticipantsByLessonId(lessonId))
    }, [])
   
    useEffect(() => {
        if (users && users.data) {
            const list = users.data.map(u => { return { name: `${u.firstName} ${u.lastName}`, value: u.id } });
            setUsersList(list);
        }
    }, [users])

    const onUserChanged = (e) => { 
        dispatch(getUsersByName(e))
    }
    const handleUserClicked = (item) => {
        setUser({ name: item.name, id: item.value })
    }

    const getLessonParticipants = () => {
        if (items) {
            return items.map((item, index) => <LessonParticipantRowExtended key={index} item={item.user} />)
        }
    }
const onSubmit = (e) =>{
    e.preventDefault();
    let isFormValid = true;
    if(ValidatorUtil.isEmptyField(user) ){
        setUserError('שדה חובה')
        isFormValid = false;
      }else{
        setUserError(null);
      }
      if(isFormValid){
        dispatch(addParticipant({userId:user.id,lessonId}))
      }
    }

    return (<>
        <div>
            <form style={{display:'flex',justifyContent:'center',alignItems:'center',height:'7rem',gap:'.5rem'}}>
                <div>
                <AutocompleteServer
                    list={usersList}
                    className="advertisement__form-users"
                    onClick={handleUserClicked}
                    initialValue={null}
                    errorMessage={userError}
                    onChange={onUserChanged} />
                    </div>
                    <input type={'submit'} className={'btn btn-primary'} onClick={onSubmit} />
            </form>
        </div>
        <table className="table table-stripped" style={{ width: '75%', margin: '5vh auto', fontSize: '1.6rem', background: '#fff' }}>
            <THead
                headers={["מזהה", "שם פרטי", "שם משפחה", "דואֿ״ל", "טלפון", "וואצאפ", ""]}
                headerColor={'rgb(215 237 253)'}
            />
            <tbody>
                {getLessonParticipants()}
            </tbody>
        </table></>)
}

export default LessonParticipants