import React,{useContext} from 'react';
import { faAddressCard } from "@fortawesome/free-solid-svg-icons";
import Menu, { SubMenu, Item as MenuItem, Divider } from 'rc-menu';
import MenuSidebarItem from './menu-sidebar-item';
import 'rc-menu/assets/index.css';
import { RouteContext } from '../../contexts/route.context';
import { pages } from '../../utils/pages.util';



const MenuSidebar = ({onCloseClicked}) =>{

    const onRouteChanged = useContext(RouteContext).onRouteChanged
    function handleSelect(info) {
        console.log('selected ', info);
      }
      
      function handleClick(info) {
        onCloseClicked();
        console.log('click ', info);
        onRouteChanged(pages[info.key])
      }
      const titleLessons = (<span style={{color:'#337ab7'}}>שיעורים</span>);
      const titleActivities = (<span style={{color:'#337ab7'}}>פעילויות</span>);

    return(<Menu
        onSelect={handleSelect}
        defaultActiveFirst
        onClick={handleClick}
      >
        <MenuItem eventKey='home' onClick={onCloseClicked}>
        <MenuSidebarItem to={''} title={'דף הבית'} />
        </MenuItem>
        <MenuItem eventKey='contacts' onClick={onCloseClicked}>
        <MenuSidebarItem to={'contacts'} title={'יצירת קשר'} />
        </MenuItem>
        
        <SubMenu title={titleLessons} key="1">
          <MenuItem key="1-1" eventKey='lessons'>
          <MenuSidebarItem to={'lessons'} title={'שיעורים'} />
          </MenuItem>
          {/* <Divider /> */}
          <MenuItem key="1-2" eventKey='lessonsReferences'>
          <MenuSidebarItem to={'lessonsReferences'} title={'מראי מקומות'} />
          </MenuItem>
          {/* <Divider /> */}
          <MenuItem key="1-3" eventKey='weeklylessonsschedule'>
          <MenuSidebarItem to={'weeklylessonsschedule'} title={'השיעורים השבועיים'} />
          </MenuItem>
          <MenuItem key="1-4" eventKey='lessonslecurers'>
          <MenuSidebarItem to={'lessonslecurers'} title={'מרצים'} />
          </MenuItem>
        </SubMenu>
        <SubMenu title={titleActivities} key="actvities">
          <MenuItem key="actvities-1" eventKey='activities'>
          <MenuSidebarItem  to={'activities'} title={'בדיקת מזוזות'} />
          </MenuItem>
          <MenuItem key="actvities-1" eventKey='kashrut'>
          <MenuSidebarItem  to={'kashrut'} title={'מבצע כשרות'} />
          </MenuItem>
        </SubMenu>
        <MenuItem key={'2-1'}>
        <MenuSidebarItem to={'faqs'} title={'שאלות נפוצות'} />
        </MenuItem>
        <MenuItem key="3">
        <MenuSidebarItem to={'notifications'} title={'הודעות'} />
        </MenuItem>
      
        <MenuItem key={'4-1'}>
        <MenuSidebarItem to={'groups'} title={'קבוצות'} />
        </MenuItem>
        <MenuItem key="4-3"> 
        <MenuSidebarItem to={'users'} title={'משתמשים'} /></MenuItem>
      </Menu>)
}

export default MenuSidebar;