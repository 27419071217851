import React from 'react';

import AutocompleteItem from "./autocomplete-item";

const AutocompleteList = ({list,typed,onClick,focusIndex}) =>{
    const getList = () =>{
        if(list){
            return list.map((item,index)=><AutocompleteItem key={index} isFocus={index === focusIndex} onClick={onClick} item={item} typed={typed} />)
        }
    }
    return(<div id="myInputautocomplete-list" className="autocomplete-items">
        {getList()}
    </div>)
}

export default AutocompleteList;